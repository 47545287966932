<div
(click)="showForm()"
[ngClass]="alert.notes && alert.notes.length > 0 ? 'styleNotes align-items-start':'commentBoxes'"
class="my-3 d-flex border border-secondary rounded cursorPointer">
    <i class="fal fa-comment-dots mx-2"
    *ngIf="!(alert.notes && alert.notes.length > 0)"
    ></i>
    <span class="flex-grow-1 text-left"
    *ngIf="!(alert.notes && alert.notes.length > 0)">
        {{'ADD_COMMENT_OPTIONAL'| translate}}</span>
    <app-cr-notes-list class="wd-75"
        *ngIf="alert.notes && alert.notes.length > 0"
        [alert]="alert"
    ></app-cr-notes-list>
    <button mat-flat-button (click)="showForm()"
        class="mx-2 placeToTheRight">
        <i
        [ngClass]="alert.notes && alert.notes.length > 0 ? 'fa-light fa-pen-to-square':'fa-light fa-plus'">
        </i>
    </button>
</div>
<div class="mb-3">
    <app-cr-notes-form
        *ngIf="showNoteForm"
        [alert]="alert"
        [addedCameraVisitNotes]="addedCameraVisitNotes"
        (noteTextKeeped)="handleNoteText($event)"
        [noteText]="noteText"
        (onCloseByCancel)="hideForm()">
    </app-cr-notes-form>
</div>
