import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  HostListener,
} from "@angular/core";
import { ApiService } from "../../services/api.service";
import { ActivatedRoute } from "@angular/router";
import { Location, CommonModule } from "@angular/common";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { AnimationCollapse } from "../../animations";
import { Angular2Csv } from "angular2-csv";
import * as moment from "moment-timezone";
import {
  DeviceResidentCompany,
  Device,
  Device as DeviceModel,
} from "../../models/device.model";
import { LocationModel } from "../../models/location.model";
import { HelperService } from "../../services/helper.service";
import { forkJoin, fromEvent, of } from "rxjs";
import { map } from "rxjs/operators";
import { Sort, MatSortModule } from "@angular/material/sort";
import { orderBy } from "lodash";
import { MomentPipe, HighlightSearch, WrapHyphenPipe } from "../../pipes/pipes";
import { DeviceImportLockCsvComponent } from "./device-import-lock-csv/device-import-lock-csv.component";
import { LocationAddComponent } from "../location/location-add/location-add.component";
import { LocationInfoComponent } from "../location/location-info/location-info.component";
import { DeviceAddComponent } from "./device-add/device-add.component";
import { DeviceKeyInfoComponent } from "./device-key-info/device-key-info.component";
import { DisplayconfigComponent } from "./displayconfig/displayconfig.component";
import { DeviceControlComponent } from "./device-control/device-control.component";
import { DeviceSettingsComponent } from "./device-settings/device-settings.component";
import { DeviceInfoComponent } from "./device-info/device-info.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DeviceService } from "../../services/device.service";
import { EverwatchFormComponent } from "../everwatch-form/everwatch-form.component";
import { ExtractLocationsPipe } from "./device-pipes";


@Component({
  selector: "devices",
  templateUrl: "./devices.component.html",
  styleUrls: ["./devices.component.css"],
  animations: [AnimationCollapse],
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    MatSortModule,
    DeviceInfoComponent,
    DeviceSettingsComponent,
    DeviceControlComponent,
    DisplayconfigComponent,
    DeviceKeyInfoComponent,
    DeviceAddComponent,
    LocationInfoComponent,
    LocationAddComponent,
    DeviceImportLockCsvComponent,
    EverwatchFormComponent,
    TranslateModule,
    MomentPipe,
    HighlightSearch,
    WrapHyphenPipe,
    ExtractLocationsPipe,
  ],
})
export class DevicesComponent implements OnInit {
  //Init global variables and services
  constructor(
    private api: ApiService,
    private helper: HelperService,
    private route: ActivatedRoute,
    private location: Location,
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef,
    private service: DeviceService
  ) { }

  // Used to shift Mobile devices location to bottom of the Device Locations list. Name is case sensitive.
  private mobileDevicesLocationServerName: string = "Mobile devices";
  // Used to rename Mobile Devices location from API to EMA.
  private mobileDevicesLocationUIName: string = "EMA";
  user: any;
  locationList: LocationModel[];
  deviceList: DeviceLocation[];
  basestationList: Device[] = [];
  filteredDeviceList: DeviceLocation[] = [];
  keyList: any[];
  keyListCollapse: boolean = false;
  deviceListRaw: DeviceModel[];
  editMode: boolean = false;
  editModePage: number = 0;
  selectedDev: Device;
  selectedDevInfo: DeviceModel;
  selectedDevInfoLoaded: boolean = false;
  selectedLocation: LocationModel;
  deviceResidents: DeviceResidentCompany[];
  devInformationText: string;
  devUpdateError: boolean;
  collapsed: boolean = false;
  locationsCollapsed: boolean = false;
  devicesCollapsed: boolean = false;
  manuallyCollapsed: boolean = false;
  _deviceFilter: string;
  @ViewChild("deviceSearchInput", { static: true })
  deviceSearchInput: ElementRef;
  @ViewChild("deviceSearchButton", { static: true })
  deviceSearchButton: ElementRef;
  devicesLoaded: boolean;
  generalInformationText: string;
  lockImport: boolean = false;
  deviceManagementEmpty: boolean = false;
  isDeviceManagementEmptyChecked: boolean = false;
  showEverWatchForm: boolean = false;
  searchDevice: any = {
    showEmpty: false,
    type: [],
    selectSettings: {
      enableSearch: true,
      dynamicTitleMaxItems: 1,
      checkedStyle: "fontawesome",
      containerClasses: "fullWidth",
      buttonClasses: "btn btn-outline-dark fullWidth",
      selectionLimit: 1,
      autoUnselect: true,
      closeOnSelect: true,
      showUncheckAll: true,
    },
    selectTexts: {
      defaultTitle: "",
      searchPlaceholder: "",
      uncheckAll: "",
    },
  };

  columnWidther = [
    "width: 8%",
    "width: 20%",
    "width: 20%",
    "",
    "width: 19%",
    "width: 11%",
    "width: 11%",
    "width: 10%",
  ];

  digitalKeyEnabled: boolean;
  iotCommsEnabled: boolean;

  onMobileScreen: boolean;
  @HostListener('window:resize', ['$event'])
  onResize() {
    let screenWidth = window.innerWidth;
    if (screenWidth > 576) {
      this.onMobileScreen = false;
    } else {
      this.onMobileScreen = true;
    }
  }

  get deviceFilter(): string {
    return this._deviceFilter;
  }

  set deviceFilter(value: string) {
    this._deviceFilter = value;

    if (this._deviceFilter) {
      this.filteredDeviceList
        = this.deviceList
          .filter(_location => _location.devices) // Do the search filtering only for the locations that contain devices
          .map((_location) => {
            const devices: Device[] = _location.devices.filter(
              (device) =>
                this.devicesContains([device], this.deviceFilter) ||
                this.devicesContains(device.pairedDevices, this.deviceFilter)
            );
            this.changeDevicesCollapseState(devices, true);

            return new DeviceLocation(
              _location.id,
              _location.name,
              devices,
              true,
              _location.deviceCount,
              _location.baseCount
            );
          })
          .filter(_location => _location.devices.length > 0); // Hide locations that don't match the search
    } else {
      this.filteredDeviceList = this.deviceList;
      this.collapsed = false;
    }
  }

  private devicesContains(devices: Device[], filter: string): boolean {
    if (devices) {
      return devices.some(
        ({ id, name, additionalInfo, additionalAlarmInfo, deviceType }) =>
          [
            id,
            name,
            this.getDeviceType(deviceType),
            additionalInfo,
            additionalAlarmInfo,
          ].some((value) => value.toLowerCase().includes(filter))
      );
    }
    return false;
  }

  isDeviceManagementEmpty(empty: boolean) {
    this.deviceManagementEmpty = empty;
    this.isDeviceManagementEmptyChecked = true;
    this.cdRef.detectChanges();
  }

  changeCollapseState(locationId: string, state: boolean): void {
    const location: DeviceLocation = this.filteredDeviceList.find(
      (deviceLocation) => {
        return deviceLocation.id === locationId;
      }
    );
    this.changeLocationCollapseState(location, state);
    this.changeDevicesCollapseState(location.devices, state);
    this.manuallyCollapsed = true;

    // Check if the previous location had devices with pairedDevices if true then we keep previous value. If not then check if there are any of them in current location.
    // Reason to do this check is that when there are no pairedDevices we don't want to try close them at HandleCollapse(); if there are none.
    this.devicesCollapsed = this.devicesCollapsed
      ? true
      : location.devices?.some((device) => {
        if (device.pairedDevices) {
          return device.pairedDevices.length !== 0;
        }
      });
  }

  private changeLocationCollapseStateById(
    deviceLocationId: string,
    state: boolean
  ): void {
    const deviceLocation: DeviceLocation = this.filteredDeviceList.find(
      (_deviceLocation) => {
        return _deviceLocation.id === deviceLocationId;
      }
    );

    if (deviceLocation) {
      this.changeLocationCollapseState(deviceLocation, state);
    }
  }

  private changeDeviceCollapseStateById(
    deviceId: string,
    state: boolean
  ): void {
    let device: Device;

    this.filteredDeviceList.some((_deviceLocation) => {
      if (_deviceLocation.devices) {
        return _deviceLocation.devices.some((_device) => {
          device = _device;
          return _device.id === deviceId || _device.basestationId === deviceId;
        });
      } else {
        return false;
      }
    });

    if (device) {
      this.changeDevicesCollapseState([device], state);
    }
  }

  private changeLocationCollapseState(
    deviceLocation: DeviceLocation,
    state: boolean
  ): void {
    deviceLocation.collapseState = state;
    this.collapsed = state;
  }

  private changeDevicesCollapseState(devices: Device[], state: boolean): void {
    if (devices) {
      devices.forEach((device) => {
        device.collapseState = state;
      });
    }
    this.collapsed = state;
  }

  private changeAllLocationsCollapseState(state: boolean): void {
    this.filteredDeviceList.forEach((deviceLocation) => {
      this.changeLocationCollapseState(deviceLocation, state);
    });
  }

  private changeAllDevicesCollapseState(state: boolean): void {
    this.filteredDeviceList.forEach((deviceLocation) => {
      this.changeDevicesCollapseState(deviceLocation.devices, state);
    });
  }

  handleCollapsing(expand: boolean): void {
    if (expand) {
      if (!this.locationsCollapsed) {
        this.changeAllLocationsCollapseState(expand);
        this.locationsCollapsed = expand;
      } else {
        this.changeAllDevicesCollapseState(expand);
        this.devicesCollapsed = expand;
        this.manuallyCollapsed = false;
      }
      return;
    }

    if (!expand) {
      if (this.devicesCollapsed) {
        this.changeAllDevicesCollapseState(expand);
        this.devicesCollapsed = expand;
      } else {
        this.changeAllLocationsCollapseState(expand);
        this.locationsCollapsed = expand;
        this.manuallyCollapsed = false;
      }
    }
  }

  private findDeviceById(deviceId: string): Device {
    let resultDevice: Device = null;
    let found: boolean;
    this.filteredDeviceList.some((_deviceLocation) => {
      if (found) {
        return;
      }
      if (_deviceLocation.devices) {
        return _deviceLocation.devices.some((_device) => {
          if (found) {
            return;
          }
          if (_device.pairedDevices) {
            _device.pairedDevices.some((pairedDevice) => {
              if (pairedDevice) {
                if (pairedDevice.id === deviceId) {
                  found = true;
                  resultDevice = pairedDevice;
                  return found;
                }
              }
            });
          }
          if (_device.id === deviceId) {
            found = true;
            resultDevice = _device;
            return found;
          }
        });
      }
      return found;
    });
    return resultDevice;
  }

  exportDeviceList() {
    let csvHeaders = [
      "ID",
      "Name",
      "Location",
      "Address",
      "Postal code",
      "City",
      "GSM",
      "Type",
      "Software version",
      "Added to the system",
    ];
    var csvData = [];

    if (this.user.roleLevel >= 50) {
      csvHeaders.push(...["Additional information", "Device information"]);
    }

    // Push rest of the headers separately to mantain correct order
    csvHeaders.push(...["Latest contact registered", "Battery status"]);

    let filename = "devices-" + moment().format("YYYY-MM-DD");
    let options = {
      fieldSeparator: ";",
      quoteStrings: '"',
      decimalseparator: ".",
      showTitle: false,
      headers: csvHeaders,
    };
    // Define what information is included in the file
    this.deviceListRaw.forEach((row) => {
      // Exclude keys from CSV list
      if (row.deviceType !== "F0-1") {
        var csvRow: any = {};
        csvRow.DeviceId = `="${row.id}"`;
        csvRow.Name = row.name || "ID-" + row.id;
        csvRow.Location = row.locationName;
        csvRow.Address = row.address;
        csvRow.PostCode = row.postCode;
        csvRow.City = row.postOffice;
        csvRow.Gsm = `="${(row.gsmNumber ? row.gsmNumber : "")}"`;
        csvRow.DeviceType = this.getDeviceType(row.deviceType);
        csvRow.Firmware = row.softwareVersion;
        csvRow.AddedToSystem = moment(row.addedToSystem).format("l LTS");
        if (this.user.roleLevel >= 50) {
          csvRow.AdditionalInfo = row.additionalInfo;
          csvRow.DeviceInformation = row.additionalAlarmInfo;
        }
        csvRow.lastContact = moment(row.lastConnection).format("l LTS");
        csvRow.batteryState = row.batteryState;
        csvData.push(csvRow);
      }
    });
    //Generate the file and send it to the browser
    new Angular2Csv(csvData, filename, options);
  }

  getDeviceType(type: string): string {
    return this.helper.getDeviceType(type);
  }

  getDeviceIconClass(dev: DeviceModel) {
    return this.api.getDeviceIconClass(dev.deviceType);
  }

  parseDeviceListResponse(res: any[]): DeviceLocation[] {
    let list: Device[] = [];
    //First add all non paired devices to list
    res.forEach((dev) => {
      let baseId = dev.basestationId;
      if (!baseId || baseId === "00000000") {
        //Push all PL-103 devices into own list
        if (dev.deviceType !== "F0-1") {
          list.push(dev);
        } else {
          if (!this.keyList) {
            this.keyList = [];
          }
          this.keyList.push(dev);
        }
      }
    });
    //Then add all paired devices under correct basestation
    res.forEach((dev) => {
      let baseId = dev.basestationId;
      if (baseId && baseId !== "00000000") {
        //Loop through list and find basestation with same id where device is paired
        for (let i = 0; i < list.length; i++) {
          if (list[i].id === baseId) {
            //Initialize pairedDevices list if it's undefined
            if (!list[i].pairedDevices) {
              list[i].pairedDevices = [];
            }
            list[i].pairedDevices.push(dev);
            return;
          }
        }
        list.push(dev);
      }
    });
    //Finally group all devices by location
    let locationList: DeviceLocation[] = JSON.parse(
      JSON.stringify(this.locationList)
    );
    //Go through previously created device list with paired devices and add each device to correct location in the location list
    list.forEach((dev) => {
      for (let i = 0; i < locationList.length; i++) {
        if (locationList[i].id === dev.locationId) {
          if (!locationList[i].devices) {
            locationList[i].devices = [];
            locationList[i].baseCount = 0;
            locationList[i].deviceCount = 0;
          }
          //If device stars with 31 it's base so increase base count with one
          if (dev.id.startsWith("31")) {
            locationList[i].baseCount++;
          }
          //Increase total number of devices with one in all cases
          locationList[i].deviceCount++;
          //If device has also paired devices add those in total device count
          if (dev.pairedDevices) {
            locationList[i].deviceCount += dev.pairedDevices.length;
          }
          locationList[i].devices.push(dev);
          return;
        }
      }
    });
    //Move Mobile Devices location to bottom of the Device Locations list and rename it to EMA
    const mobileDeviceLocationIndex: number = locationList.findIndex(
      (location) => location.name === this.mobileDevicesLocationServerName
    );
    if (mobileDeviceLocationIndex !== -1) {
      const mobileDeviceLocation: DeviceLocation[] = locationList.splice(
        mobileDeviceLocationIndex,
        1
      );
      mobileDeviceLocation[0].name = this.mobileDevicesLocationUIName;
      locationList.push(...mobileDeviceLocation);
    }

    return locationList;
  }

  locationBaseCount(location: DeviceLocation): number {
    let count: number = 0;
    location.devices.forEach((dev) => {
      if (dev.id.startsWith("31")) {
        count++;
      }
    });
    return count;
  }

  editDevice(deviceId: string): void {
    let editDevice: Device;

    this.selectedDevInfoLoaded = false;
    //Clear selected location if device is selected for editing
    this.selectedLocation = null;
    this.lockImport = false;
    //Clear the information text
    this.devInformationText = "";
    this.devUpdateError = false;
    //Set edit mode always to true when device row has been clicked
    this.editMode = true;
    //Reset edit mode page to information
    this.editModePage = 0;
    //If user clicked the same row again close the edit mode and set URL back to /device

    if (this.selectedDev) {
      if (deviceId === this.selectedDev.id) {
        this.location.go("device");
        this.editMode = false;
        this.editModePage = 0;
        this.selectedDev = null;
        return;
      }
    }

    //Assign deviceId to this.selectedDev.id so we can quickly update UI table active selection.
    //In updateDev we will retrieve full details of the this.selectedDev
    this.selectedDev = new Device();
    this.selectedDev.id = deviceId;

    // Get EverWatchId only for 07 prefixed devices (EverWatch device)
    const everWatchId$ = deviceId.startsWith('07') ? this.api.getEverWatchId(deviceId) : of("");

    // Get device information and EverWatchId in parallel
    forkJoin({
      deviceInfo: this.api.getDeviceInfo(deviceId),
      everWatchId: everWatchId$
    }).subscribe(({ deviceInfo, everWatchId }) => {
      editDevice = deviceInfo;
      editDevice.everWatchId = everWatchId;

      if (editDevice) {
        // If edit mode is active set selected device in the memory. Otherwise clear it
        if (this.editMode) {
          // If user comes with straight URL into system monitoring device change it to location edit instead
          if (editDevice.deviceType === "18-192") {
            this.selectedDev = null;
            // Find correct location
            this.selectedLocation = this.locationList.find((obj) => {
              return obj.id === editDevice.locationId;
            });
            this.location.go("device");
            return;
          }
          // Change URL to selected device
          this.location.go("device/" + editDevice.id);
          this.selectedDevInfo = editDevice;
          this.selectedDev = editDevice;
          this.selectedDevInfoLoaded = true;
          // Load device sub classes if customer has specific feature enabled
          if (this.iotCommsEnabled) {
            this.api
              .getDeviceTypeSubClasses(this.selectedDev.deviceType)
              .subscribe((res) => {
                if (res) {
                  //Convert device subclass number to translated text
                  this.selectedDev.allSubClasses = res;
                  let subClass = res.find(
                    (x) => x.devSubClass === this.selectedDev.devSubClass
                  );
                  if (subClass) {
                    this.selectedDev.devSubType =
                      "SUBTYPE_" + subClass.subClassDescription;
                  }
                }
              });
          }
        } else {
          this.selectedDev = null;
        }
      }
    });
  }

  editLocation(location: DeviceLocation, event: any): void {
    if (event) {
      event.stopPropagation();
    }
    //Clear selected device if location is selected for editing
    this.selectedDev = null;
    this.location.go("device");
    //If same location is selected again hide the editing panel and clear variables
    if (this.selectedLocation && this.selectedLocation.id == location.id) {
      this.editMode = false;
      this.selectedLocation = null;
      return;
    }
    this.editMode = true;
    this.showEverWatchForm = false;
    this.selectedLocation = this.locationList.find((obj) => {
      return obj.id == location.id;
    });
  }

  locationUpdated(location: LocationModel): void {
    //Update name and id for the device listing
    for (let i = 0; i < this.filteredDeviceList.length; i++) {
      if (this.filteredDeviceList[i].id === location.id) {
        this.filteredDeviceList[i].name = location.name;
      }
    }

    //Update all values into location list
    let locIndex: number = this.locationList.findIndex(
      (x) => x.id === location.id
    );
    this.locationList[locIndex] = location;
  }

  locationAdded(newLocation: LocationModel): void {
    this.editMode = false;
    const loc: DeviceLocation = new DeviceLocation(
      newLocation.id,
      newLocation.name,
      []
    );
    this.deviceList.push(loc);
    this.locationList.push(newLocation);
  }

  locationDeleted(location: LocationModel): void {
    //Set information text about the delete and clear it after 5 seconds
    this.translate.get(["LOCATION_DELETED"]).subscribe((t) => {
      this.generalInformationText = t.LOCATION_DELETED;
    });
    setTimeout(() => {
      this.generalInformationText = "";
    }, 5000);
    this.editMode = false;

    let deletedLoc: DeviceLocation = this.deviceList.find((obj) => {
      return obj.id === location.id;
    });

    this.deviceList.splice(this.deviceList.indexOf(deletedLoc), 1);
  }

  updateDev(updatedDevice: Device): void {
    // Find the device location from the device list
    const deviceLocation = this.deviceList.find(loc => loc.devices?.some(dev => dev.id === updatedDevice.id));

    this.selectedDev = updatedDevice;
    this.devInformationText = this.translate.instant("DEVICE_INFORMATION_UPDATED");

    // If device location was changed move it to new location
    if (deviceLocation && updatedDevice?.locationId !== deviceLocation.id) {
      // Remove device from the old location
      const index = deviceLocation.devices?.findIndex(dev => dev.id === updatedDevice?.id);

      if (index !== -1) {
        deviceLocation.devices?.splice(index, 1);
      }

      // Find the new location from the device location list
      const newLocation = this.deviceList.find((obj) => obj.id === updatedDevice?.locationId);

      // Add device to the new location and sort the list alphabetically by name if the location exists
      if (newLocation) {
        newLocation.devices = [...(newLocation.devices ?? []), updatedDevice];
        newLocation.devices.sort((a, b) => a.name.localeCompare(b.name));
        deviceLocation.collapseState = false;
        newLocation.collapseState = true;
      }
    }
    else {
      // If device location was not changed update the device in the current location
      const oldDevice = deviceLocation?.devices?.find(device => device.id === updatedDevice?.id);
      if (oldDevice) Object.assign(oldDevice, updatedDevice);
    }

    // Incase of search filter is active, trigger the search again to reflect the changes
    this.applySearch(this.deviceSearchInput.nativeElement?.value);
  }

  updateKey(data: any[]): void {
    //0 - device name
    //1 - enabled

    this.translate.get(["DEVICE_INFORMATION_UPDATED"]).subscribe((t) => {
      this.devInformationText = t.DEVICE_INFORMATION_UPDATED;
    });

    //If incoming name is different than current then change it
    if (data[0] !== this.selectedDev.name) {
      this.selectedDev.name = data[0];
    }
    //If enabled has been changed then change it
    if (data[1] !== this.selectedDev.keyEnabled) {
      this.selectedDev.keyEnabled = data[1];
    }
  }

  updateError(value: boolean): void {
    this.editModePage = 0;
    this.devUpdateError = value;
  }

  deviceDeleted(id: string): void {
    this.selectedDev.toBeDeleted = true;
    this.editModePage = 0;
    this.translate.get(["DEVICE_MARKED_DELETE"]).subscribe((t) => {
      this.devInformationText = t.DEVICE_MARKED_DELETE;
    });
  }

  cancelDeviceDelete(id: string): void {
    this.selectedDev.toBeDeleted = false;
    this.editModePage = 0;
    this.translate.get(["DEVICE_DELETE_CANCELED"]).subscribe((t) => {
      this.devInformationText = t.DEVICE_DELETE_CANCELED;
    });
  }

  deviceForceDeleted(dev: DeviceModel): void {
    this.editMode = false;
    this.editModePage = 0;
    //First find the correct location where device belongs to from the DeviceLocation list
    let location: DeviceLocation = this.deviceList.find((obj) => {
      return obj.id === dev.locationId;
    });
    //Then find the correct device
    let baseStation: Device = location.devices.find((obj) => {
      //If device has basestationId set then it's paired device so return the basestation
      if (dev.basestationId && dev.basestationId != "00000000") {
        return obj.id === dev.basestationId;
      } else {
        //If not it's root level device ie. camera or Vega
        return obj.id === dev.id;
      }
    });

    if (dev.basestationId && dev.basestationId != "00000000") {
      //If device has basestation then finally find the correct object from the paired devices array
      let device: Device = baseStation.pairedDevices.find((obj) => {
        return obj.id === dev.id;
      });
      //Remove device from paired device list
      baseStation.pairedDevices.splice(
        baseStation.pairedDevices.indexOf(device),
        1
      );
    } else {
      //If device was at root level then remove it from that list
      location.devices.splice(location.devices.indexOf(baseStation), 1);
    }
  }

  settingsTransferred(value: number): void {
    this.selectedDev = this.findDeviceById(this.selectedDev.id);
    this.selectedDev.tasksToBase = value;
    this.editModePage = 0;
    this.translate.get(["DEVICE_PARAM_TO"]).subscribe((t) => {
      this.devInformationText = t.DEVICE_PARAM_TO;
    });
  }

  settingTransferCancelled(value: boolean): void {
    if (value) {
      this.selectedDev = this.findDeviceById(this.selectedDev.id);
      this.selectedDev.tasksToBase = 0;
      this.selectedDev.tasksFromBase = 0;
      this.editModePage = 0;
      this.translate.get(["SETTING_TRANSFERS_CANCELLED"]).subscribe((t) => {
        this.devInformationText = t.SETTING_TRANSFERS_CANCELLED;
      });
    }
  }

  deviceAdded(id: string): void {
    this.generalInformationText = this.translate.instant("DEVICE_ADDED_SUCCESSFULLY");
    this.editMode = false;

    let data = {
      fullInfo: true,
    };
    // When new device is added load list of all devices from the database for full info
    this.devicesLoaded = false;
    this.api.getDevices(data).subscribe((res) => {
      // Filter system monitoring device out from the device list
      res = res.filter((obj) => {
        return obj.deviceType != "18-192";
      });
      this.devicesLoaded = true;
      this.deviceListRaw = res;
      this.filteredDeviceList = this.deviceList = this.parseDeviceListResponse(res);

      let device: Device = res.filter((x) => x.id === id)[0];
      if (device) {
        // Find basestation for the device and set it collapse state to true
        let baseStation: any = res.filter(
          (x) => x.id === device.basestationId
        )[0];
        if (baseStation) {
          baseStation.collapseState = true;
        }
        this.editDevice(device.id);
      }
    });
  }

  deviceReplaced(id: string): void {
    if (!id) {
      this.selectedDev.replaceDevice = "";
      return;
    }
    this.editModePage = 0;
    this.translate.get(["DEVICE_MARKED_REPLACE"]).subscribe((t) => {
      this.devInformationText = t.DEVICE_MARKED_REPLACE;
    });
    this.selectedDev.replaceDevice = id;
  }

  closeAddBaseForm(): void {
    this.location.go("device");
    this.selectedDev = null;
    this.selectedLocation = null;
    this.editMode = false;
  }

  addNewBase(): void {
    this.location.go("device/new");
    this.selectedDev = null;
    this.selectedLocation = null;
    this.editMode = true;
    this.lockImport = false;
    this.showEverWatchForm = false;
  }

  addNewLocation(): void {
    this.location.go("device");
    this.editMode = true;
    this.showEverWatchForm = false;
    this.selectedDev = null;
    this.lockImport = false;
    this.selectedLocation = new LocationModel();
  }

  addEverwatch(): void {
    this.location.go("device/everwatch/new");
    this.showEverWatchForm = true;
    this.editMode = true;
    this.selectedDev = null;
    this.selectedLocation = null;
    this.lockImport = false;
  }

  showLockImportCard(): void {
    if (this.lockImport) {
      this.lockImport = false;
      this.editMode = false;
      return;
    }
    this.lockImport = true;
    this.editMode = true;
    this.showEverWatchForm = false;
    this.selectedDev = null;
    this.selectedLocation = null;
  }

  showDeviceSettings(devInfo: DeviceModel): boolean {
    //Define all json fields which enables settings panel
    if (
      devInfo.bitParaSettings ||
      devInfo.movementTimeLimit ||
      devInfo.deviceType === "04-1" ||
      devInfo.deviceType === "04-2" ||
      devInfo.deviceType === "05-3" ||
      devInfo.deviceType === "05-4" ||
      devInfo.deviceType === "05-5" ||
      devInfo.deviceType === "05-7" ||
      devInfo.deviceType === "06-2" ||
      devInfo.deviceType === "05-8" ||
      devInfo.deviceType === "05-9" ||
      devInfo.deviceType === "32-3" ||
      devInfo.deviceType === "32-8" ||
      devInfo.deviceType === "32-1" ||
      devInfo.deviceType === "32-6" ||
      devInfo.deviceType.substr(0, 2) === "34" ||
      devInfo.deviceType.substr(0, 2) === "89"
    ) {
      return true;
    }
    return false;
  }

  // When Nfc tag is moved from hub to another, unpair from current hub and relocate it to under the new hub
  nfcMoved(data: [string, string]) {
    const nfcDevId = data[0];
    const newBaseId = data[1];

    // Get all devices from the location list into one array
    let devices = [].concat(
      ...this.filteredDeviceList
        .map((location) => location.devices)
        .filter((devices) => devices)
    );

    // Find and unpair(=remove) the tag from old hub
    let unpairedNfcTag;
    for (let device of devices) {
      if (device.pairedDevices) {
        const index = device.pairedDevices.findIndex(
          (device) => device.id === nfcDevId
        );
        if (index !== -1) {
          unpairedNfcTag = device.pairedDevices.splice(index, 1)[0];
          unpairedNfcTag.basestationId = newBaseId;
          if (device.pairedDevices.length === 0)
            // Set to undefined to hide paired devices list collapse
            device.pairedDevices = undefined;
          break;
        }
      }
    }
    let hub = devices.find((device) => device.id === newBaseId);

    // Initialize array if undefined and place moved tag under the new hub
    if (!hub.pairedDevices) hub.pairedDevices = [];
    hub.pairedDevices.push(unpairedNfcTag);
    hub.collapseState = true;
  }

  // Get basestations and cameras from device list
  parseHubDevices(devices: Device[]): Device[] {
    return devices.filter((d) =>
      ["31", "34"].includes(d.deviceType.substring(0, 2))
    );
  }

  populateTable() {
    return new Promise((resolve, reject) => {
      this.api.getLocations().subscribe((res) => {
        this.locationList = res;
        //Parameters for device list API
        let data = {
          fullInfo: true,
        };
        //Get all customer devices and parse the response
        this.devicesLoaded = false;
        this.api.getDevices(data).subscribe(
          (res) => {
            this.devicesLoaded = true;
            this.deviceListRaw = res;
            if (res) {
              //Filter system monitoring device out from the device list
              res = res.filter((obj) => {
                return obj.deviceType != "18-192";
              });
              this.deviceList = this.parseDeviceListResponse(res);
              this.basestationList = this.parseHubDevices(res);

              this.filteredDeviceList = this.deviceList;
            }
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
    });
  }

  collapseDeviceRow(dev: DeviceModel, event: any) {
    if (event) {
      event.stopPropagation();
    }
    dev.collapseState = !dev.collapseState;
  }

  onlineInLastDay(timestamp: string): boolean {
    const currentDate = moment().utc().unix();
    const dateToCompare = moment(timestamp).unix();
    const dayInS = 86400;
    return currentDate - dateToCompare < dayInS;
  }

  sortKeys(sort: Sort): void {
    if (!sort.active || sort.direction == "") {
      return;
    }

    this.keyList = orderBy(
      this.keyList,
      [sort.active, sort.active === "lowBatteryWarning" ? "keyEnabled" : ""],
      sort.direction
    );
  }

  sortDeviceList(sort: Sort, index: number): void {
    if (!sort.active || sort.direction === "") {
      return;
    }

    const lowBattery = [
      "softwareUpdateOngoing",
      "tasksToBase",
      "tasksFromBase",
      "toBeDeleted",
    ];

    this.filteredDeviceList[index].devices = orderBy(
      this.filteredDeviceList[index].devices,
      [
        sort.active,
        sort.active === "onlineStatus" ? "lastConnection" : "",
        sort.active === "lowBatteryWarning" ? lowBattery : "",
      ],
      sort.direction
    );

    this.filteredDeviceList[index].devices = this.filteredDeviceList[
      index
    ].devices.map((el: Device) => {
      if (!el.pairedDevices || (el.pairedDevices && !el.pairedDevices.length)) {
        return el;
      }

      el.pairedDevices = orderBy(
        el.pairedDevices,
        [
          sort.active,
          sort.active === "lowBatteryWarning"
            ? [...lowBattery, "replaceDevice"]
            : "",
          sort.active === "onlineStatus" ? "lastConnection" : "",
        ],
        sort.direction
      );

      return el;
    });
  }

  ngOnInit() {
    this.digitalKeyEnabled = this.service.featureEnabled("DigitalKey")
    this.iotCommsEnabled = this.service.featureEnabled("IotComms")

    this.onResize()
    this.user = JSON.parse(localStorage.getItem("user"));
    this.searchDevice.showEmpty = this.user.roleLevel <= 50;
    //Get translated device type list from local storage
    let devTypeList = localStorage.getItem("devType");
    //If list is not in storage download it from the server and set it to local storage
    if (!devTypeList) {
      this.api
        .getDeviceTranslations(localStorage.getItem("language"))
        .subscribe((res) => {
          localStorage.setItem("devType", JSON.stringify(res));
          devTypeList = res;
        });
    }

    let id: string;
    //If user comes with direct URL get the device ID from the URL
    this.route.params.subscribe((params) => (id = params["id"]));
    //If ID is found open correct device in edit mode
    if (id && id !== "new") {
      //If device is PL-103 with prefix F0 change key list collapse state
      if (id.substr(0, 2) === "F0") {
        this.keyListCollapse = true;
      }
      this.editDevice(id);
    } else {
      if (id === "new") {
        this.addNewBase();
      }
    }

    //Wait for data to load before doing anything
    this.populateTable().then(() => {
      //Make sure that all locations are NOT collapsed at the beginning EXCEPT if there is only one location
      if (this.filteredDeviceList.length === 1) {
        this.changeAllDevicesCollapseState(true);
        this.changeAllLocationsCollapseState(true);
        this.locationsCollapsed = true;
        this.devicesCollapsed = true;
      } else {
        this.changeAllDevicesCollapseState(false);
        this.changeAllLocationsCollapseState(false);
      }

      // If we are coming straight to EditMode (check if editDevice !== null || undefined) collapse that device location and the device basestation
      if (this.editMode && id !== "new" && this.selectedDev) {
        this.changeLocationCollapseStateById(this.selectedDev.locationId, true);
        this.changeDeviceCollapseStateById(
          this.selectedDev.basestationId,
          true
        );
        this.manuallyCollapsed = true;
      }
    });

    //Set translations on page load
    this.translate
      .get(["FILTER_DEVICE_TYPE", "FILTER_UNCHECK_ALL", "SEARCH"])
      .subscribe((t) => {
        this.searchDevice.selectTexts.defaultTitle = t.FILTER_DEVICE_TYPE;
        this.searchDevice.selectTexts.searchPlaceholder = t.SEARCH;
        this.searchDevice.selectTexts.uncheckAll = t.FILTER_UNCHECK_ALL;
      });

    // Get company active residents
    this.api.getCompanyResidents(1).subscribe((res) => {
      this.deviceResidents = res;
      if (res?.length) {
        // Widths sum should be 99%
        this.columnWidther = [
          "width: 7%",
          "width: 19%",
          "width: 15%",
          "width: 11%",
          "width: 18%",
          "width: 10%",
          "width: 10%",
          "width: 9%",
        ];
      }
    });

    if (this.deviceSearchButton?.nativeElement) {
      fromEvent(this.deviceSearchButton?.nativeElement, "click")
        .pipe(map((event: any) => this.deviceSearchInput.nativeElement?.value))
        .subscribe((query: string) => (this.applySearch(query)));
    }
  }

  onEnterKeydown(event) {
    this.applySearch(event.target.value);
  }

  applySearch(input: string) {
    this.deviceFilter = input.toLowerCase();
  }
}

export class DeviceLocation {
  id: string;
  name: string;
  devices: Device[];
  collapseState: boolean;
  deviceCount: number;
  baseCount: number;

  constructor(
    id: string,
    name: string,
    devices: Device[],
    collapseState: boolean = false,
    deviceCount: number = 0,
    baseCount: number = 0
  ) {
    this.id = id;
    this.name = name;
    this.devices = devices;
    this.collapseState = collapseState;
    this.deviceCount = deviceCount;
    this.baseCount = baseCount;
  }
}
