<div class="row" *ngIf="device">
  <div class="col-lg-12" *ngIf="!deleting && !forceDeleting">
    <!--Move NFC tag-->
    <div class="row mb-2" #ctrlElement *ngIf="checkDeviceType('moveNFC')">
      <div class="col-lg-12">
        <strong>{{ "MOVE_NFC_TAG" | translate }}</strong>
        <p>{{ "MOVE_NFC_TAG_INFO" | translate }}</p>

        <div class="form-group">
          <button
            class="btn btn-primary mr-3"
            [disabled]="selectedBasestation.length === 0"
            (click)="moveNfcTag()"
          >
            <span class="fa-solid fa-arrow-left-long-to-line"></span>
            {{ "MOVE" | translate }}
          </button>

          <ngx-bootstrap-multiselect
            *ngIf="basestationSelect.options.length > 0"
            [options]="basestationSelect.options"
            [settings]="basestationSelect.settings"
            [texts]="basestationSelect.texts"
            [(ngModel)]="selectedBasestation"
          ></ngx-bootstrap-multiselect>
        </div>

        <div>
          <div *ngIf="nfcMoveSuccess" class="alert alert-success" role="alert">
            {{ "NFC_MOVE_SUCCESS" | translate }}
          </div>

          <div *ngIf="nfcMoveError" class="alert alert-danger" role="alert">
            {{ "NFC_MOVE_ERROR" | translate }}
          </div>
        </div>
        <hr />
      </div>
    </div>

    <!--Replace device-->
    <div class="row mb-2" #ctrlElement *ngIf="checkDeviceType('replace')">
      <div class="col-lg-12">
        <strong>{{ "DEVICE_REPLACE_HEADER" | translate }}</strong>

        <p *ngIf="!isReplaced">
          {{ "DEVICE_REPLACE_INFO" | translate }}
        </p>

        <p *ngIf="isReplaced && device.deviceType !== '31-13'">
          {{ "DEVICE_REPLACE_INFO_CANCEL" | translate }}
        </p>

        <div class="form-inline mb-2">
          <div class="form-group mr-2">
            <input
              type="text"
              [attr.disabled]="isReplaced ? '' : null"
              class="form-control"
              placeholder="ID"
              [(ngModel)]="replaceDevice"
            />
          </div>

          <div class="form-group">
            <button
              *ngIf="!isReplaced"
              type="button"
              class="btn btn-outline-primary gray-bg"
              (click)="deviceInventoryEnabled ? openReplaceDialog() : submitReplaceDevice()"
            >
              <span class="fa-light fa-fw fa-arrow-right-arrow-left"></span>
              {{ "DEVICE_REPLACE" | translate }}
            </button>

            <button
              *ngIf="isReplaced && device.deviceType !== '31-13'"
              type="button"
              class="btn btn-outline-danger"
              (click)="cancelReplace()"
            >
              <span class="fa-light fa-ban fa-fw"></span>
              {{ "CANCEL" | translate }}
            </button>
          </div>
        </div>
        <div class="alert alert-danger col-lg-6" *ngIf="replaceError">
          {{ replaceError }}
        </div>
        <hr />
      </div>
    </div>

    <!--Delete device-->
    <div
      class="row mb-2"
      #ctrlElement
      *ngIf="!checkDeviceType('forceDelete') && user.roleLevel >= 50 && checkDeviceType('basestationDelete') && checkDeviceType('everwatchDelete')"
      [hidden]="toBeDeleted && user.roleLevel >= 200"
    >
      <div class="col-lg-12">
        <div>
          <strong>{{ "DEVICE_DELETE_HEADER" | translate }}</strong>

          <p *ngIf="device.toBeDeleted">
            {{ "DEVICE_MARKED_DELETE" | translate }}
          </p>

          <p *ngIf="!device.toBeDeleted">
            {{ "DEVICE_DELETE_INFORMATION" | translate }}
          </p>

          <button
            [disabled]="device.toBeDeleted"
            class="btn btn-outline-danger"
            (click)="deleteDevice()"
          >
            <span class="fa-light fa-octagon-minus"></span>
            {{ "DELETE" | translate }}
          </button>
        </div>
      </div>
    </div>

    <!--Force delete device-->
    <div class="row mb-2" #ctrlElement *ngIf="checkDeviceType('forceDelete') && user.roleLevel >= 50 && checkDeviceType('basestationDelete') && checkDeviceType('everwatchDelete')">
      <div class="col-lg-12">
        <div>
          <strong>{{ "FORCE_DELETE" | translate }}</strong>
          <p>{{ "DEVICE_FORCE_DELETE_INFORMATION" | translate }}</p>

          <button class="btn btn-outline-danger" (click)="forceDeleteDevice()">
            <span class="fa-light fa-octagon-minus"></span>
            {{ "FORCE_DELETE" | translate }}
          </button>
        </div>
      </div>
    </div>

    <!--Cancel Deletion-->
    <div class="row mb-2" *ngIf="toBeDeleted && user.roleLevel >= 200">
      <div class="col-lg-12">
        <div>
          <strong>{{ "CANCEL_TO_BE_DELETED" | translate }}</strong>

          <p>
            {{ "CANCEL_DELETE_INFORMATION" | translate }}
          </p>

          <button class="btn btn-outline-danger" (click)="revertDelete()">
            <span class="fa-light fa-octagon-minus"></span>
            {{ "CANCEL_DELETE" | translate }}
          </button>
        </div>
      </div>
    </div>

    <!--Retransfer settings or cancel settings transfers-->
    <div class="row" #ctrlElement *ngIf="checkDeviceType('rts') && user.roleLevel >= 100">
      <div class="col-lg-12">
        <hr />

        <div *ngIf="device.tasksToBase === 0 && device.tasksFromBase === 0">
          <strong>{{ "DEVICE_RETRANSFER_SETTINGS" | translate }}</strong>
          <p>{{ "DEVICE_RETRANSFER_INFORMATION" | translate }}</p>

          <button class="btn btn-outline-primary" (click)="reTransferSettings()">
            <span class="fa-light fa-down-to-line fa-lg"></span>
            {{ "DEVICE_RETRANSFER_SETTINGS" | translate }}
          </button>
        </div>

        <div *ngIf="device.tasksToBase > 0 || device.tasksFromBase > 0">
          <strong>{{ "CANCEL_TRANSFERS" | translate }}</strong>
          <p>{{ "CANCEL_TRANSFERS_INFORMATION" | translate }}</p>

          <button class="btn btn-outline-primary" (click)="cancelSettingsTransfers()">
            <span class="fa-light fa-xmark"></span>
            {{ "CANCEL_TRANSFERS" | translate }}
          </button>
        </div>
      </div>
    </div>

    <!--Update device firmware-->
    <div class="row" #ctrlElement *ngIf="firmwareVersions">
      <div class="col-lg-12">
        <hr />

        <strong>{{ "UPDATE_DEVICE_FIRMWARE" | translate }}</strong>
        <p>{{ "CURRENT_FIRMWARE" | translate }}: {{ device.softwareVersion }}</p>

        <p class="alert alert-info" *ngIf="device.softwareUpdateOngoing">
          {{ "FIRMWARE_UPDATE_ONGOING" | translate }}
          <span *ngIf="device.swUdStartTime !== '00:00' || device.swUdEndTime !== '00:00'">
            ({{ device.swUdStartTime }} - {{ device.swUdEndTime }})
          </span>.
          {{ "WAIT_FOR_FIRMWARE_UPDATE" | translate }}
        </p>

        <div *ngIf="firmwareVersions">
          <form
            [formGroup]="otaUpdateForm"
            (ngSubmit)="formSubmitSubject.next()"
            [hidden]="device.softwareUpdateOngoing"
          >
            <div class="form-group">
              <mat-form-field>
                <mat-label>{{ "AVAILABLE_FIRMWARE_VERSIONS" | translate }}</mat-label>
                <mat-select formControlName="fw">
                  <mat-option
                    *ngFor="let firmware of firmwareVersions"
                    [ngClass]="{ testFirmware: !firmware.productionSw, recommendedFirmware: firmware.recommendedSw }"
                    [value]="firmware"
                  >
                    <span *ngIf="!firmware.productionSw">
                      {{ "NOT_FOR_PRODUCTION" | translate }} -
                    </span>
                    {{ "REL" + firmware.swVersion }} - {{ firmware.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- Show scheduled update times only for terminal devices and if firmware is selected -->
            <div [hidden]="device.id.substring(0, 2) === '31' || !otaUpdateForm.value.fw">
              <div class="form-group">
                <mat-form-field>
                  <mat-label>{{ "OTA_UPDATE_TIMING" | translate }}</mat-label>
                  <mat-select formControlName="fwUpdateTiming">
                    <mat-option value="0">{{ "IMMEDIATELY" | translate }}</mat-option>
                    <mat-option value="1">{{ "DURING_TIMES" | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-group">
                <div class="form-row" *ngIf="otaUpdateForm.value.fwUpdateTiming === '1'">
                  <div class="col">
                    <label>{{ "REPORT_START_TIME" | translate }}</label>

                    <input
                      class="form-control form-control-sm"
                      [ngxTimepicker]="fwUpdateStartTime"
                      [format]="24"
                      [name]="fwUpdateStartTime"
                      formControlName="fwUpdateStartTime"
                    />
                    <ngx-material-timepicker #fwUpdateStartTime></ngx-material-timepicker>
                  </div>

                  <div class="col">
                    <label>{{ "REPORT_END_TIME" | translate }}</label>

                    <input
                      class="form-control form-control-sm"
                      [ngxTimepicker]="fwUpdateEndTime"
                      [format]="24"
                      [name]="fwUpdateEndTime"
                      formControlName="fwUpdateEndTime"
                    />
                    <ngx-material-timepicker #fwUpdateEndTime></ngx-material-timepicker>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <button type="submit" class="btn btn-outline-primary" [disabled]="!otaUpdateForm.valid">
                <span class="fa-light fa-fw fa-arrows-rotate"></span>
                {{ "UPDATE" | translate }}
              </button>
            </div>

            <div class="form-group">
              <div class="alert alert-danger" *ngIf="otaError">
                {{ "ERROR" | translate }}
              </div>

              <div class="alert alert-success" *ngIf="otaSuccess">
                {{ "FIRMWARE_UPDATE_SUCCESS" | translate }}
              </div>
            </div>
          </form>

          <div *ngIf="device.softwareUpdateOngoing">
            <button class="btn btn-outline-danger" (click)="cancelOTA()">
              <span class="fa-light fa-fw fa-xmark"></span>
              {{ "CANCEL_UPDATE" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Device delete confirmation -->
  <div class="col-lg-12" *ngIf="deleting || forceDeleting">
    <strong *ngIf="deleting">{{ "DEVICE_DELETE_CONFIRMATION" | translate : { devName: device.name ? device.name : device.id } }}</strong>

    <strong *ngIf="forceDeleting">
      {{ "DEVICE_FORCE_DELETE_CONFIRMATION" | translate : { devName: device.name ? device.name : device.id } }}
    </strong>

    <p *ngIf="forceDeleting">
      {{ "DEVICE_FORCE_DELETE_CONFIRMATION_INFORMATION" | translate }}
    </p>

    <div *ngIf="pairedDevices">
      {{ "DEVICE_DELETE_PAIRED" | translate }}:

      <ul class="pairedDevDelete">
        <li *ngFor="let paired of pairedDevices">
          {{ paired.name }} ({{ paired.id }}) - {{ getDeviceType(paired.deviceType) }}
        </li>
      </ul>
    </div>

    <div class="mt-2">
      <button class="btn btn-outline-danger mr-1" (click)="confirmDelete()">
        <span class="fa-light fa-octagon-minus"></span>
        {{ "DELETE" | translate }}
      </button>

      <button class="btn btn-outline-primary" (click)="cancelDelete()">
        <span class="fa-light fa-xmark"></span>
        {{ "CANCEL" | translate }}
      </button>
    </div>
  </div>
</div>
