<form [formGroup]="noteForm" (ngSubmit)="formSubmitSubject.next()">
    <div class="form-group">
        <label>{{'ADD_NOTE' |translate}}</label>
        <textarea class="form-control" formControlName="text" (input)="noteKeeper()"></textarea>
    </div>
    <div class="form-group">
        <button type="submit"
                [disabled]="!noteForm.valid"
                class="btn btn-outline-primary">
            {{'SAVE' | translate}}
        </button>
        <button class="btn btn-outline-danger ml-2"
                type="button"
                (click)="closeForm()">
            {{'CANCEL' |translate}}
        </button>
    </div>
</form>

