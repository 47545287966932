<div class="row" *ngIf="device">
    <div class="col-lg-12">
        <div class="row">

            <display-device-info
                class="col-lg-5 col-xl-12 mb-2 px-0"
                *ngIf="!editMode"
                [device]="device"
                [userRoleLevel]="user?.roleLevel"
                [residentsLength]="residents?.length"
                (enterEditMode)="toggleEditMode()">
            </display-device-info>

            <!-- Edit device information -->
            <!-- <edit-device-info
                *ngIf="editMode"
                [device]="device"
                [residents]="residents"
                [currentDeviceResidentId]="currentDeviceResidentId"
                [deviceResidentDisplayName]="deviceResidentDisplayName"
                [signalLevels]="signalLevels"
                [residentSelect]="residentSelect"
                (deviceUpdateSuccess)="onDeviceUpdateSuccess($event)"
                (onError)="handleError()"
                (updateDeviceNames)="onDeviceUpdateNames($event)"
                (exitEditMode)="toggleEditMode()"
                (signalLevelsUpdate)="onSignalLevelsUpdate($event)">
            </edit-device-info> -->

            <ng-container *ngIf="editMode">
                <div class="col-lg-12">
                    <form [formGroup]="devEditForm">
                        <h5>{{ "EDIT_DEVICE_SETTINGS" | translate }}</h5>

                        <div class="row">
                            <div class="divider"></div>

                            <div class="col-lg-3">
                                <h6>{{ "DEVICE_INFORMATION" | translate }}</h6>
                            </div>

                            <div class="col-lg-9">
                                <div class="form-group">
                                    <label><strong>{{ "NAME" | translate }}</strong></label>
                                    <input type="text" class="form-control" placeholder="{{ 'NAME' | translate }}"
                                        formControlName="name" />
                                </div>

                                <div class="form-group">
                                    <label><strong>{{ "DEVICE_ADDITIONAL_ALARM_INFO" | translate }}</strong></label>
                                    <textarea
                                        class="form-control"
                                        placeholder="{{ 'DEVICE_ADDITIONAL_ALARM_INFO' | translate }}"
                                        formControlName="additionalAlarmInfo">
                                    </textarea>
                                </div>

                                <div class="form-group">
                                    <label><strong>{{ "DEVICE_ADDITIONAL_INFO" | translate }}</strong></label>
                                    <textarea
                                        class="form-control"
                                        placeholder="{{ 'DEVICE_ADDITIONAL_INFO' | translate }}"
                                        formControlName="additionalInfo">
                                    </textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="residents?.length > 0">
                            <div class="divider"></div>

                            <div class="col-lg-3">
                                <h6>{{ "RESIDENT" | translate }}</h6>
                            </div>

                            <div class="col-lg-9">
                                <div class="form-group">
                                    <label><strong>{{ "RESIDENT" | translate }}</strong></label>

                                    <ngx-bootstrap-multiselect
                                        [options]="residentSelect.residentList"
                                        [settings]="residentSelect.settings"
                                        [texts]="residentSelect.texts"
                                        formControlName="deviceResidentId"
                                        #selectedResident></ngx-bootstrap-multiselect>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!device.basestationId || device.basestationId === '00000000'" class="row">
                            <div class="divider"></div>

                            <div class="col-lg-3">
                                <h6>{{ "LOCATION" | translate }}</h6>
                            </div>

                            <div class="col-lg-9">
                                <div class="form-group">
                                    <label><strong>{{ "LOCATION" | translate }}</strong></label>

                                    <mat-form-field class="selector-full-width">
                                        <mat-select formControlName="locationId">
                                            <mat-option *ngFor="let loc of locations" [value]="loc.id">
                                                {{ loc.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <!-- Display if device is Danalock/other lock and user is main user or admin-->
                        <div class="row" *ngIf="isLock() && user.roleLevel >= 100">
                            <div class="divider"></div>

                            <div class="col-lg-3">
                                <h6>{{ "LOCK_INFORMATION" | translate }}</h6>
                            </div>

                            <div class="col-lg-9">
                                <div class="form-group">
                                    <label><strong>{{ "LOCK_SERIAL_NUMBER" | translate }}</strong></label>
                                    <input type="text" class="form-control"
                                        placeholder="{{ 'LOCK_SERIAL_NUMBER' | translate }}"
                                        formControlName="lockSerialNumber" />
                                </div>

                                <div class="form-group">
                                    <label><strong>{{ "LOCKED_DOOR_TYPE" | translate }}</strong></label>

                                    <mat-form-field class="selector-full-width">
                                        <mat-select formControlName="lockedDoorTypeID">
                                            <mat-option *ngFor="let type of lockedDoorTypes" [value]="type.value">
                                                {{ type.name | translate }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <!-- Show if door type is not Entry Door -->
                                <div *ngIf="devEditForm.value.lockedDoorTypeID !== '1'" class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label><strong>{{ "ROOM_NUMBER" | translate }}</strong></label>
                                            <input type="text" class="form-control"
                                                placeholder="{{ 'ROOM_NUMBER' | translate }}"
                                                formControlName="roomNumber" />
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label><strong>{{ "FLOOR_NUMBER" | translate}}</strong></label>
                                            <input type="text" class="form-control"
                                                placeholder="{{ 'FLOOR_NUMBER' | translate }}"
                                                formControlName="floorNumber" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="divider"></div>

                            <div class="col-lg-3">
                                <h6>{{ "ADDRESS" | translate }}</h6>
                            </div>

                            <div class="col-lg-9">
                                <div class="form-group">
                                    <label><strong>{{ "ADDRESS" | translate }}</strong></label>
                                    <input type="text" class="form-control" placeholder="{{ 'ADDRESS' | translate }}"
                                        formControlName="address" />
                                </div>

                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label><strong>{{ "POSTCODE" | translate}}</strong></label>
                                            <input type="text" class="form-control"
                                                placeholder="{{ 'POSTCODE' | translate }}" formControlName="postCode" />
                                        </div>
                                    </div>

                                    <div class="col-lg-8">
                                        <div class="form-group">
                                            <label><strong>{{ "POSTOFFICE" | translate }}</strong></label>
                                            <input type="text" class="form-control"
                                                placeholder="{{ 'POSTOFFICE' | translate }}"
                                                formControlName="postOffice" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Show only if specific pay to use features are enabled and conditions for child elements apply -->
                        <div *ngIf="(!device.basestationId || device.basestationId === '00000000' || device.allSubClasses) && isFeatureEnabled('IotComms')"
                            class="row">
                            <div class="divider"></div>

                            <div class="col-lg-3">
                                <h6>{{ "ADVANCED_SETTINGS" | translate }}</h6>
                            </div>

                            <!--Show only for top level devices-->
                            <div *ngIf="!device.basestationId || device.basestationId === '00000000'" class="col-lg-9">
                                <div class="form-group">
                                    <label><strong>{{ "EXTERNAL_IDENTIFIER" | translate }}</strong></label>
                                    <input type="text" class="form-control" formControlName="externalIdentifier" />
                                </div>
                            </div>

                            <!--Show only if there are sub classes for device-->
                            <div class="col-lg-9" *ngIf="device.allSubClasses">
                                <div class="form-group">
                                    <label><strong>{{ "DEV_SUB_CLASS" | translate }}</strong></label>

                                    <mat-form-field class="selector-full-width">
                                        <mat-select formControlName="devSubClass">
                                            <mat-option *ngFor="let subClass of device.allSubClasses"
                                                [value]="subClass.devSubClass">
                                                {{ "SUBTYPE_" + subClass.subClassDescription }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="divider"></div>
                    </form>

                    <div class="form-group">
                        <button
                            appThrottleClick
                            (throttledClick)="submitDeviceEdit()"
                            [disabled]="devEditForm.invalid || devEditForm.pristine"
                            class="btn btn-outline-primary gray-bg mr-1">
                            <span class="fal fa-save"></span>
                            {{ "SAVE" | translate }}
                        </button>

                        <button
                            type="reset"
                            class="btn btn-outline-danger"
                            (click)="resetForm(); toggleEditMode()">
                            <span class="fal fa-times"></span>
                            {{ "CANCEL" | translate }}
                        </button>
                    </div>
                </div>
            </ng-container>

            <!-- Edit device activation information -->
            <div class="col-lg-7 col-xl-12 px-0" *ngIf="!editMode">
                <img
                    *ngIf="!activationsParsed"
                    class="fadeLoader centerLoader logoSmall"
                    src="assets/img/loading_logo.svg" />

                <ng-container *ngIf="activationsParsed">
                    <div *ngIf="activationsSaveError" class="alert alert-danger">
                        <span>{{ "CONFLICTING_ROUTES_ON_ACTIVATION" | translate }}</span>
                        <span><strong>{{ activationsSaveError }}</strong></span>
                    </div>

                    <div *ngIf="activationsSaveSuccess" class="alert alert-success">
                        <span>{{ "DEVICE_ACTION_UPDATED" | translate }}: </span>
                        <span><strong>{{ activationsSaveSuccess }}</strong></span>
                    </div>

                    <form [formGroup]="activationEditForm">
                        <div class="card-borders mt-sm-4 mt-4 mt-lg-0">
                            <table class="table table-borderless table-responsive centerTableContent">
                                <thead>
                                    <tr class="border-bottom">
                                        <th style="width: 45%">
                                            {{ "ACTIVATION" | translate }}
                                            <span matTooltip="{{ 'TOOLTIP_ACTIVATION' | translate }}"
                                                class="fa fa-fw fa-info-circle"></span>
                                        </th>
                                        <th style="width: 45%">
                                            {{ "ALARM_ROUTE" | translate }}
                                            <span matTooltip="{{ 'TOOLTIP_ALARM_ROUTE' | translate }}"
                                                class="fa fa-fw fa-info-circle"></span>
                                        </th>
                                        <th style="width: 9%"></th>
                                        <th style="width: 1%"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <ng-container *ngFor="let name of activationNames; let i = index">
                                        <!-- Every activation dropdown has it's own formControlGroup, which are stored inside FormArray -->
                                        <tr class="border-top" [formGroup]="activationsFormArray.controls[i]">
                                            <td>{{ name }}</td>
                                            <td>
                                                <mat-form-field class="device-alarm-route-select">
                                                    <!--  Show No Alarm Routes label if routeSelection is empty-->
                                                    <!-- <mat-label *ngIf="routeSelection.empty">
                                 <span>{{ NO_ALARM_ROUTE" | translate }}</span>
                               </mat-label> -->

                                                    <mat-select
                                                        formControlName="selectedRouteIds"
                                                        #routeSelection
                                                        placeholder="{{ routeSelection.empty ? ('NO_ALARM_ROUTE' | translate) : null}}"
                                                        multiple>
                                                        <mat-optgroup
                                                            *ngFor="let alarmRouteLocation of availableAlarmRouteLocations"
                                                            [label]="alarmRouteLocation.locationName">
                                                            <ng-container
                                                                *ngFor="let route of alarmRouteLocation.routes">
                                                                <!--Skip default routes-->
                                                                <mat-option *ngIf="!route.defaultRoute"
                                                                    [value]="route.id">
                                                                    {{ route.name }}
                                                                </mat-option>
                                                            </ng-container>
                                                        </mat-optgroup>
                                                    </mat-select>
                                                </mat-form-field>
                                            </td>
                                            <td *ngIf="user?.roleLevel >= 50" colspan="2">
                                                <span
                                                    *ngIf="!routeSelection.empty"
                                                    [id]="'clearActivation' + i"
                                                    class="fa-regular fa-fw fa-times fa-lg clear-routes-button d-inline pr-2"
                                                    (click)="clearActivationRoutes(i)"></span>

                                                <span
                                                    *ngIf="activationsFormArray.controls[i].dirty"
                                                    [id]="'saveActivation' + i"
                                                    class="fas fa-fw fa-save fa-lg save-activation-button d-inline"
                                                    (click)="submitActivationEdit(i)">
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </ng-container>
            </div>
        </div>
        <device-signal-levels class="row mt-3"
            *ngIf=" signalLevels?.length > 0 && user?.roleLevel === 200 && !editMode"
            [signalLevels]="signalLevels"
            [networkSurveyResults]="networkSurveyResults"
            [deviceType]="device.deviceType">
        </device-signal-levels>
    </div>
</div>
<div class="row" *ngIf="!device">
    <div class="col-lg-12">
        <img
            class="fadeLoader centerLoader logoSmall"
            src="assets/img/loading_logo.svg" />
    </div>
</div>
