<div class="container-fluid main">
    <div class="alert alert-success mt-3" *ngIf="registryUpdated">
        {{ "DEVICE_REGISTRY_UPDATED_WITH_ID" | translate }} '{{ deviceIdAdded }}'
    </div>

    <div class="alert alert-success mt-3" *ngIf="registryUpdatedMany">
        {{ devsAddedToReg }} {{ "DEVICES_REGISTRY_UPDATED" | translate }}
    </div>

    <div class="alert alert-danger mt-3" *ngIf="registryUpdatedError">
        {{ "DEVREGISTRY_UPDATE_ERROR" | translate }}
    </div>

    <div class="alert alert-danger mt-3" *ngIf="registryUpdatedManyError">
        {{ "DEVREGISTRY_UPDATE_ERROR" | translate }}
    </div>

    <device-add-inventory
        *ngIf="showAddProductDialog"
        [customerSelectionData]="customerSelectionData"
        [locationList]="locationList"
        (closeAddFormEvent)="closeAddBaseForm()"
        (deviceAddedEvent)="deviceAdded($event)"
        (deviceAddedError)="deviceAddedError($event)">
    </device-add-inventory>

    <div class="d-flex mb-4 justify-content-between">
        <div class="d-flex">
            <button class="btn btn-primary mr-2" (click)="openAddProductDialog()">
                <i class="fa-solid fa-plus"></i>
                {{ "ADD_PRODUCT" | translate }}
            </button>
            <button (click)="downloadCsv()" class="btn btn-light mr-2">
                <i id="downloadCSV" class="fa-light fa-download"></i>
                {{"DOWNLOAD_CSV" | translate}}
            </button>
            <button (click)="filterDialog()" id="filter" #toggleFilterDialog class="mr-2 btn btn-light">

                {{ "FILTER" | translate }}
                <i *ngIf="!showFilterDialog" class="fas fa-chevron-down" id="menuicon"></i>
                <i *ngIf="showFilterDialog" class="fas fa-chevron-up" #toggleFilterDialog></i>
            </button>

            <!--
      <form [formGroup]="uploadDeviceInventoryCsvFile" (ngSubmit)="formSubmitSubject.next($event)" class="d-flex">
        <label for="file" class="btn btn-primary mb-0 mr-2">
          {{ "CHOOSE_FILE" | translate }}
        </label>

        <input
          type="file"
          id="file"
          accept=".csv"
          formControlName="file"
          (change)="handleCSVInput($event)"
          hidden
        />

        <button class="btn btn-primary" type="submit" [disabled]="!CSVToUpload?.name">
          {{ "UPLOAD" | translate }}
        </button>
      </form>
      -->
        </div>
        <div class="d-flex">
            <input
                #deviceSearchInput
                class="border rounded rightCornersSquare shadow-sm"
                type="text"
                (keydown.enter)="search()"
                placeholder=" {{ 'SEARCH' | translate }}... " />

            <button (click)="search()" class="btn btn-primary leftCornersSquare shadow-sm">
                <i class="fa-sharp fa-solid fa-magnifying-glass"></i>
            </button>
        </div>
    </div>

    <p *ngIf="!pageInited">{{ "LOADING" | translate }}...</p>

    <ng-container *ngIf="pageCount > 0">
        <!--
        <div class="p-0 mb-2">
            <mat-accordion multi="true">
                <mat-expansion-panel class="summary mat-typography">

                    <mat-expansion-panel-header class="summary-head">
                        <mat-panel-title>
                            <h4 class="summary-head-content">
                                {{ "SUMMARY" | translate }}
                            </h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="summary-content" *ngIf="devTypeSummary; else loading">
                        <div class="summary-row" *ngIf="devTypeSummary.length > 0">
                            <div class="colbox">
                                <p>&nbsp;</p>
                                <p>{{ "TOTAL_AMOUNT" | translate }}:</p>
                                <p>{{ "IN_USE" | translate }}:</p>
                                <p>{{ "EVERON_INVENTORY" | translate }}:</p>
                                <p>{{ "SERVICE_OR_REPAIR" | translate }}:</p>
                            </div>

                            <div class="colbox" *ngFor="let type of devTypeSummary">
                                <p><b>&nbsp;{{ getDeviceType(type.deviceType)}}&nbsp;</b></p>
                                <p>{{ type.totalAmount }}</p>
                                <p>{{ type.inUseAmount }}</p>
                                <p>{{ type.inStockAmount }}</p>
                                <p>{{ type.inServiceAmount }}</p>
                            </div>
                        </div>

                        <p *ngIf="devTypeSummary.length === 0">
                            {{ "NO_RESULT" | translate }}
                        </p>
                    </div>

                    <ng-template #loading>
                        <div class="summary-content">
                            <p>{{ "LOADING" | translate }}...</p>
                        </div>
                    </ng-template>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        -->

        <table id="deviceTable">
            <thead>
                <tr class="pl-5">
                    <ng-container *ngFor="let header of headerInfo">
                        <th class="header" [class]="header.class" [ngClass]="{ hpointer: !header.sortDisabled }"
                            (click)="sort(header.sorter)">
                            <div class="d-inline-flex">
                                <div>{{ header.headName | translate }}</div>
                                <div class="ml-2" style="margin-top: 1px;">
                                    <i
                                        *ngIf="header.sorter !== selectedFilters.sortKey || selectedFilters.sortBy == 0"
                                        class="fa-solid fa-sort"
                                        style="color: #a9a9a9">
                                    </i>

                                    <i
                                        *ngIf="header.sorter === selectedFilters.sortKey && selectedFilters.sortBy === -1"
                                        class="fa-solid fa-sort-up">
                                    </i>

                                    <i
                                        *ngIf="header.sorter === selectedFilters.sortKey && selectedFilters.sortBy === 1"
                                        class="fa-solid fa-sort-down">
                                    </i>
                                </div>
                            </div>
                        </th>
                    </ng-container>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let device of dataSource">
                    <ng-container *ngIf="pageLoaded">
                        <tr>
                            <td
                                innerHTML="{{device.devTypeName | highlight : deviceFilter}} ({{ (device.devType | deviceTypeCode | highlight : deviceFilter) || '-' }})">
                            </td>
                            <td innerHTML="{{ (device.deviceName | highlight : deviceFilter) || '-' }}"></td>
                            <td innerHTML="{{ (device.devId | highlight : deviceFilter) || '-' }}"></td>
                            <td *ngIf="isDeviceBasestation(device)">
                                <div class="selectWrapper">
                                    <select name="location"
                                        id="location"
                                        #deviceLocation
                                        (change)="onSelected(deviceLocation, device, $event)"
                                        (click)="$event.stopPropagation()"
                                        class="form-select form-select-sm">
                                        <option [value]="null" [disabled]="true" default>
                                            {{ "EVERON_INVENTORY" | translate }}
                                        </option>
                                        <option [value]="null" *ngIf="device.statusInEveronStock">
                                            {{ "CUSTOMER_INVENTORY" | translate }}
                                        </option>
                                        <option [value]="null" selected *ngIf="!device.statusInEveronStock">
                                            {{ "CUSTOMER_INVENTORY" | translate }}
                                        </option>

                                        <option [value]="device.locationId" *ngIf="device.locationId" selected>
                                            {{ device.location }}
                                        </option>

                                        <ng-container *ngIf="device.locationId">
                                            <option
                                                *ngFor="let location of removeDuplicateLocation(companyGroupLocations, device.location)"
                                                [disabled]="device.companyId !== location.companyID"
                                                [value]="location.locationID">
                                                {{ location.locationName }}
                                            </option>
                                        </ng-container>

                                        <ng-container *ngIf="!device.locationId">
                                            <option
                                                *ngFor="let location of removeDuplicateLocation(companyGroupLocations, device.location)"
                                                [value]="location.locationID">
                                                {{ location.locationName }}
                                            </option>
                                        </ng-container>

                                    </select>
                                </div>
                            </td>

                            <ng-container *ngIf="!isDeviceBasestation(device)">
                                <td *ngIf="device.location"
                                    innerHTML="{{ (device.location | highlight : deviceFilter) }}"></td>
                                <td *ngIf="!device.location">
                                    <select name="location"
                                        id="location"
                                        #deviceLocation
                                        (change)="onSelected(deviceLocation, device, $event)"
                                        (click)="$event.stopPropagation()"
                                        class="form-select form-select-sm" *ngIf="device.statusInEveronStock">
                                        <option [value]="null" [disabled]="true" default>
                                            {{ "EVERON_INVENTORY" | translate }}
                                        </option>
                                        <option [value]="null" *ngIf="device.statusInEveronStock">
                                            {{ "CUSTOMER_INVENTORY" | translate }}
                                        </option>
                                    </select>
                                    <span *ngIf="!device.statusInEveronStock">{{ "CUSTOMER_INVENTORY" | translate
                                        }}</span>
                                </td>
                            </ng-container>

                            <td innerHTML="{{ (device.locationAddress | highlight : deviceFilter) || '-' }}"></td>
                            <td innerHTML="{{ (device.resident | highlight : deviceFilter) || '-' }}"></td>
                            <td innerHTML="{{ (device.sSnumber | highlight : deviceFilter) || '-' }}"></td>
                            <ng-container *ngIf="bindingTimeVisible">
                                <td
                                    innerHTML="{{ (device.bindingTimeEnd | bindingTime | highlight : deviceFilter) }} {{'DAYS' |translate}}">
                                </td>
                            </ng-container>
                            <td>
                                <div class="deviceStatusBadgeWrapper">
                                    <app-feature-badge [feature]="device | resolveDeviceStatus"></app-feature-badge>
                                </div>
                            </td>
                            <td>
                                <i (click)="openDialog(device)"
                                    class="fa-regular fa-ellipsis fa-xl p-2 pr-3 cursorPointer"></i>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                <p *ngIf="!dataSource.length">{{ "NO_DEVICES_WITH_FILTER" | translate }}</p>
                <mat-spinner class="my-spinner m-2" *ngIf="!pageLoaded"></mat-spinner>
                <p *ngIf="!pageLoaded" class="m-2">{{ "LOADING" | translate }}...</p>
            </tbody>
        </table>

        <div class="d-flex align-items-baseline paginatorBox my-4">
            <div class="m-2">
                {{ "ITEMS" | translate }}: {{numberOfDevices}}
            </div>

            <mat-paginator class="ml-2 mb-1"
                [showFirstLastButtons]="true"
                [length]="numberOfDevices"
                [pageSize]="pageSize"
                [pageSizeOptions]="[25, 50, 75, 100]"
                [pageIndex]="currentPageIndex"
                (page)="handlePageEvent($event)"
                aria-label="Select page">
            </mat-paginator>
        </div>

        <div class="filter-dialog" *ngIf="showFilterDialog" #filterMenu>
            <div class="d-flex">

                <div class="order-1 p-2">
                    <p class="filter-head"><b>{{ "DEVICE_TYPE" | translate }}</b></p>
                    <section class="filter-section">
                        <p *ngFor="let devType of deviceTypeFilters">
                            <mat-checkbox (change)="isDeviceFilterChecked(devType, $event)"
                                [checked]="selectedFilters.deviceTypes.includes(devType)">
                                {{ getDeviceType(devType) }}
                            </mat-checkbox>
                        </p>
                    </section>
                </div>

                <div class="order-1 p-2">
                    <p class="filter-head"><b>{{ "LOCATION" | translate }}</b></p>
                    <section class="filter-section">
                        <p *ngFor="let location of locationFilters">
                            <mat-checkbox
                                (change)="isLocationFilterChecked(location, $event)"
                                [checked]="selectedFilters.locations.includes(location)">
                                {{ getLocationName(location) }}
                            </mat-checkbox>
                        </p>
                    </section>
                </div>

                <div class="order-1 p-2">
                    <p class="filter-head"><b>{{ "STATUS" | translate }}</b></p>
                    <section class="filter-section">
                        <p>
                            <mat-checkbox [(ngModel)]="selectedFilters.statusOnline" (change)="filterDevices()">
                                {{ "Online" | translate }}
                            </mat-checkbox>
                        </p>

                        <p>
                            <mat-checkbox [(ngModel)]="selectedFilters.statusOfflineUnder24h"
                                (change)="filterDevices()">
                                {{ "OfflineUnder24h" | translate }}
                            </mat-checkbox>
                        </p>

                        <p>
                            <mat-checkbox [(ngModel)]="selectedFilters.statusOfflineOver24h" (change)="filterDevices()">
                                {{ "OfflineOver24h" | translate }}
                            </mat-checkbox>
                        </p>

                        <p>
                            <mat-checkbox [(ngModel)]="selectedFilters.statusLowBattery" (change)="filterDevices()">
                                {{ "BATTERY_STATUS_WARNING" | translate }}
                            </mat-checkbox>
                        </p>

                        <!--
                        <p>
                            <mat-checkbox [(ngModel)]="selectedFilters.statusNotInUse" (change)="filterDevices()">
                                {{ "NOT_IN_USE_FILTER" | translate }}
                            </mat-checkbox>
                        </p>
                        -->

                        <p>
                            <mat-checkbox [(ngModel)]="selectedFilters.statusInCustomerStock"
                                (change)="filterDevices()">
                                {{ "CUSTOMER_STOCK" | translate }}
                            </mat-checkbox>
                        </p>

                        <p>
                            <mat-checkbox [(ngModel)]="selectedFilters.statusInEveronStock" (change)="filterDevices()">
                                {{ "EVERON_STOCK" | translate }}
                            </mat-checkbox>
                        </p>

                        <p>
                            <mat-checkbox [(ngModel)]="selectedFilters.statusInService" (change)="filterDevices()">
                                {{ "SERVICE_OR_REPAIR" | translate }}
                            </mat-checkbox>
                        </p>

                        <p>
                            <mat-checkbox [(ngModel)]="selectedFilters.statusLost" (change)="filterDevices()">
                                {{ "LOST" | translate }}
                            </mat-checkbox>
                        </p>
                    </section>
                </div>
            </div>
        </div>
    </ng-container>

</div>