import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ApiService } from "app/services/api.service";
import { Location, NgIf, NgClass, NgFor } from "@angular/common";
import { LocationModel } from "app/models/location.model";
import { ActivatedRoute } from "@angular/router";
import { StaffModel } from "app/models/staff.model";
import { Sort, MatSortModule } from "@angular/material/sort";
import { orderBy } from "lodash";
import { Subject, combineLatest, fromEvent, of } from "rxjs";
import { map, startWith, tap } from "rxjs/operators";
import { HighlightSearch } from "../../pipes/pipes";
import { TranslateModule } from "@ngx-translate/core";
import { LocationInfoComponent } from "../location/location-info/location-info.component";
import { StaffInfoComponent } from "./staff-info/staff-info.component";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";

@Component({
  selector: "app-staff",
  templateUrl: "./staff.component.html",
  styleUrls: ["./staff.component.css"],
  standalone: true,
  imports: [
    NgIf,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    NgClass,
    MatCardModule,
    NgFor,
    MatSortModule,
    StaffInfoComponent,
    LocationInfoComponent,
    TranslateModule,
    HighlightSearch,
  ],
})
export class StaffComponent implements OnInit {
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  customerCollapsed: boolean = true;
  loadingStaffInformation: boolean;
  staffList: StaffModel[];
  locationList: any;
  staffLocationList: any;
  selectedStaff: StaffModel;
  selectedLocation: LocationModel;
  user: any;
  @ViewChild("staffSearchInput", { static: true }) staffSearchInput: ElementRef;
  @ViewChild("staffSearchButton", { static: true })
  staffSearchButton: ElementRef;

  showLocationsWithoutStaff = false;

  queryForFilter = new Subject();
  queryForFilter$ = this.queryForFilter.asObservable();
  filteredStaffList$;

  fullStaffListObs$ = this.api.getStaffList();
  filterON;
  filterCriteria: string;

  parseStaffList(): void {
    //Make deep copy of the locationList into staffLocationList which will include staff data
    if (true) {
      this.staffLocationList = JSON.parse(JSON.stringify(this.locationList));

      if (!this.showCustomerLocation()) {
        this.staffLocationList = this.staffLocationList.filter(
          (location) => !this.isCustomerLocation(location)
        );
      }
    }

    //Loop through all staff members
    for (let i = 0; i < this.staffList.length; i++) {
      //Get location id for current staff
      let locId = this.staffList[i].locationId;
      //Loop through all locations
      for (let j = 0; j < this.staffLocationList.length; j++) {
        //Check if location has same id as current staff
        if (this.staffLocationList[j].id === locId) {
          //Initialize new empty array if it does not exists
          if (!this.staffLocationList[j].staff) {
            this.staffLocationList[j].staff = [];
          }
          //Set all locations closed for default
          this.staffLocationList[j].collapseState = this.filterON;
          //Add staff into location if location was correct
          this.staffLocationList[j].staff.push(this.staffList[i]);
        }
      }
    }

    //Sort the staff lists
    for (let i = 0; i < this.staffLocationList.length; i++) {
      if (this.staffLocationList[i].staff) {
        this.staffLocationList[i].staff.sort((a, b) => {
          return a.fullName.localeCompare(b.fullName);
        });
      }
    }
  }

  staffExists(staffLocationList) {
    let staffFound = false;
    staffLocationList?.forEach((location) => {
      if (staffFound) {
        return;
      }
      staffFound = !!location.staff;
    });
    return staffFound;
  }

  getStaffIcon(staff: StaffModel): string {
    let iconClass: string;
    // Different icon depending on staff properties
    if (staff.locked) {
      iconClass = "fa-lock-alt";
    }
    else if (staff.identityUsername) {
      iconClass = "fa-user-shield";
    }
    else {
      iconClass = "fa-user";
    }

    // Return user icon and different color for normal accounts
    switch (staff.usingLevel) {
      case 101:
        iconClass += " staffSuperUser";
        break;
      case 100:
        iconClass += " staffMainUser";
        break;
      case 50:
        iconClass += " staffUser";
        break;
      case 40:
        iconClass += " staffONONMU";
        break;
      case 10:
        iconClass += " staffEMA";
        break;
      default:
        break;
    }

    iconClass += staff.usingLevel === 101 ? " fa-solid" : " fa-light";

    return iconClass;
  }

  editStaff(staff: StaffModel): void {
    this.selectedLocation = null;
    if (this.selectedStaff === staff) {
      this.selectedStaff = null;
      this.location.go("staff");
      return;
    }
    this.loadingStaffInformation = true;
    this.selectedStaff = null;
    this.location.go("staff/" + staff.id);
    this.api.getStaffInfo(staff.id).subscribe((res) => {
      this.selectedStaff = res;
      this.loadingStaffInformation = false;
    });
  }

  staffUpdated(staffData: StaffModel) {
    //Find correct staff from lists
    let staff: StaffModel = this.staffLocationList
      .find((x) => x.id === staffData.locationId)
      .staff.find((y) => y.id === staffData.id);
    //If staf was not found from locationlist (ie. if location was changed) use the data from selectedStaff variable. Properties will be updated into UI later when staff is added under new location
    if (!staff) staff = this.selectedStaff;

    //Update properties for UI
    staff.fullName = staffData.fullName;
    staff.username = staffData.username;
    staff.usingLevel = staffData.usingLevel;
    staff.locked = staffData.locked;

    //If staff location was changed move it to new location
    if (staffData.locationId !== this.selectedStaff.locationId) {
      //Find the old location
      let oldLocation: any = this.staffLocationList.find((obj) => {
        return obj.id === this.selectedStaff.locationId;
      });
      //Remove staff from old location device list
      oldLocation.staff.splice(
        oldLocation.staff.findIndex(
          (staff) => staff.id === this.selectedStaff.id
        ),
        1
      );
      // oldLocation.staff.splice(oldLocation.staff.indexOf(this.selectedStaff), 1);

      //Find the new location from the staff location list
      for (let i = 0; i < this.staffLocationList.length; i++) {
        if (this.staffLocationList[i].id === staffData.locationId) {
          //Create new empty array if it does not exists
          if (!this.staffLocationList[i].staff) {
            this.staffLocationList[i].staff = [];
          }
          //Add edited staff into new location
          this.staffLocationList[i].staff.push(this.selectedStaff);
          this.selectedStaff.locationId = staffData.locationId;
          break;
        }
      }
    }
    //Sort the staff lists
    for (let i = 0; i < this.staffLocationList.length; i++) {
      if (this.staffLocationList[i].staff) {
        this.staffLocationList[i].staff.sort((a, b) => {
          return a.fullName.localeCompare(b.fullName);
        });
      }
    }
  }

  staffInserted(staff: StaffModel): void {
    this.staffList.push(staff);
    this.staffLocationList = null;
    this.parseStaffList();
    this.selectedStaff = staff;
    this.location.go("staff/" + staff.id);
  }

  staffDeleted(id: string): void {
    this.location.go("staff");
    this.selectedStaff = null;
    //Find correct staff from staff list
    let staff: StaffModel = this.staffList.find((obj) => {
      return obj.id === id;
    });
    //Remove staff from the list
    this.staffList.splice(this.staffList.indexOf(staff), 1);
    this.staffLocationList = null;
    //Parse the staff list with removed staff member
    this.parseStaffList();
  }

  addNewStaff(): void {
    this.selectedStaff = new StaffModel();
  }

  editLocation(location: LocationModel): void {
    this.selectedStaff = null;

    /*
    if (location.id.substring(0, 2) === "C0") {
      return;
    }
    */

    if (location === this.selectedLocation) {
      this.selectedLocation = null;
      return;
    }
    this.selectedLocation = location;
  }

  closeLocation(location: LocationModel) {
    this.selectedLocation = null;
  }

  locationUpdated(location: LocationModel): void {
    //Update name and id for the staff listing
    for (let i = 0; i < this.staffLocationList.length; i++) {
      if (this.staffLocationList[i].id === location.id) {
        this.staffLocationList[i].name = location.name;
      }
    }
    //Update all values into location list
    let locIndex: number = this.locationList.findIndex(
      (x) => x.id === location.id
    );
    this.locationList[locIndex] = location;
  }

  locationAdded(newLocation: LocationModel): void {
    this.locationList.push(newLocation);
  }

  locationDeleted(location: LocationModel): void {
    this.locationList.splice(this.locationList.indexOf(location), 1);
  }

  showCustomerLocation() {
    return this.user.locationId.substring(0, 2) === "C0";
  }

  isCustomerLocation(location: any) {
    return location.id.substring(0, 2) === "C0";
  }

  featureEnabled(feature: string): boolean {
    let features = JSON.parse(localStorage.getItem("features"));
    if (features) {
      let index = features.indexOf(feature);
      if (index !== -1) {
        return true;
      }
    }
    return false;
  }

  sortData(sort: Sort, index: number) {
    if (!sort.active || sort.direction == "") {
      return;
    }

    if (sort.active === "usingLevel") {
      // Display highest-level users in ascending and lowest-level in descending order
      sort.direction = sort.direction === 'asc' ? 'desc' : "asc";
    }

    this.staffLocationList[index].staff = orderBy(
      this.staffLocationList[index].staff,
      sort.active,
      sort.direction
    );
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("user"));

    this.api.getLocations().subscribe((res) => {
      this.locationList = res;
      if (this.user.locationId.startsWith("C0")) {
        //Add customer information into location list since staff members can be in root level
        let customerInfo = JSON.parse(localStorage.getItem("customerInfo"));
        let customer: LocationModel = new LocationModel();
        customer.id = customerInfo.customerId;
        customer.name = customerInfo.name;
        customer.address = customerInfo.address;
        customer.postCode = customerInfo.postCode;
        customer.postOffice = customerInfo.postOffice;
        this.locationList = !this.locationList ? this.locationList = [] : this.locationList;
        this.locationList.unshift(customer);
      }

    });
    this.filteredStaffList$ = combineLatest([
      this.fullStaffListObs$,
      this.queryForFilter$.pipe(startWith("all")),
    ])
      .pipe(
        map(([fullStaffList, queryString]) => {
          // filter staff list on given fields of each profile
          if (queryString !== "all") {
            this.showLocationsWithoutStaff = true;
            this.filterON = true;

            return fullStaffList.filter((profile) => {
              let whereToSearchFields = [
                "username",
                "firstName",
                "familyName",
                "id",
                "email",
                "gsmNumber",
              ];
              let match = false;

              whereToSearchFields.forEach((field) => {
                if (match) {
                  return;
                }
                match = profile[field].toLowerCase().includes(queryString);
              });

              return match;
            });

            // return the full list (unfiltered)
          } else {
            this.filterON = false;
            return fullStaffList;
          }
        })
      )
      .subscribe((res) => {
        if (res) {
          this.staffList = res;
        } else {
          this.staffList = [];
        }

        //Check if user comes with URL containing the staff ID
        let id;
        //Get ID from the URL
        this.route.params.subscribe((params) => {
          id = params["id"];
        });
        //If ID was found from the URL find correct staff from the staff list and set that as a selected staff
        if (id) {
          this.api.getStaffInfo(id).subscribe((res) => {
            this.selectedStaff = res;
          });
        }

        //Parse staff list so each staff is under correct location
        this.parseStaffList();
      });

    fromEvent(this.staffSearchButton.nativeElement, "click")
      .pipe(
        map(() =>
          this.staffSearchInput.nativeElement.value.toLowerCase().trim()
        )
      )
      .subscribe((query: string) => this.applyFilterCriteria(query));
  }

  // two use cases: one for Enter button click and one for Search button click
  applyFilterCriteria(query: string) {
    if (query.length > 0) {
      this.filterCriteria = query;
      this.queryForFilter.next(query);
      this.showLocationsWithoutStaff = false;
    } else {
      this.clearFilter();
    }
  }

  onEnterKeydown(event) {
    let query = event.target.value.toLowerCase().trim();
    this.applyFilterCriteria(query);
  }

  clearFilter() {
    this.staffSearchInput
      ? ((<HTMLInputElement>this.staffSearchInput.nativeElement).value = "")
      : null;
    this.filterCriteria = "";
    this.showLocationsWithoutStaff = false;
    this.queryForFilter.next("all");
  }
}
