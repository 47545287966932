<table
  infiniteScroll
  [infiniteScrollDistance]="0"
  [infiniteScrollThrottle]="500"
  [infiniteScrollDisabled]="infiniteScrollDisabled"
  (scrolled)="loadNextEvents.emit()"
  class="table table-hover tableLatest"
>
  <thead>
    <tr>
      <th></th>
      <th>{{ "START_TIME" | translate }}</th>
      <th>{{ "DEVICE" | translate }}</th>
      <th>{{ "EVENT" | translate }}</th>
      <th>{{ "USER_OR_RECIPIENT" | translate }}</th>
      <th>{{ "END_TIME" | translate }}</th>
    </tr>
  </thead>

  <tbody>
    <ng-container *ngFor="let row of cameraEvents; let e = even; let i = index">
      <!-- Header row -->
      <tr class="trCollapse" [ngClass]="{ trEven: e }" (click)="expandRow(row, i)">
        <td><span class="fa-light" [ngClass]="!row.expanded ? 'fa-plus' : 'fa-minus'"></span></td>
        <td>{{ row.visitStarted | moment : "l LTS" }} </td>
        <td><div class="eye-pos-fix" *ngIf="row.camera.anonymizedPropExists"><i class="fa-solid fa-eye-slash"></i></div>{{ row.camera.displayName }}</td>
        <td>{{ row.camera.visitType | langCode | translate }}</td>
        <td>
            <div class="eye-pos-fix" *ngIf="row.camera.anonymizedPropExists && !row.camera.anonymizedStatus"><i class="fa-solid fa-eye"></i></div>
            <span [ngClass]="{'text-danger': !row.userName }">
                <span *ngIf="!row.userName" class="fa-light fa-fw fa-exclamation-triangle text-danger"></span>
                {{ row.userName || "MISSED_CAMERA_VISIT" |translate}}
            </span>
        </td>
        <td>{{ row.visitEnd | moment : "l LTS" }}</td>
      </tr>

      <!-- Detail row -->
      <tr *ngIf="row.expanded" [ngClass]="{ trEven: e }">
        <td colspan="6">
          <div class="row">
            <div class="col-md-3">
              <div>
                <strong> {{ "START_TIME" | translate }}:</strong> {{ row.visitStarted | moment : "l LTS"}}
                <br />
                <strong> {{ "END_TIME" | translate }}: </strong> {{ row.visitEnd | moment : "l LTS"}}
                <br />
                <strong> {{ "CAMERA_VISIT_DURATION" | translate }}: {{ row.visitStarted | visitDuration : row.visitEnd }} </strong>
                <br />
              </div>
            </div>

            <div class="col-md-3">
                <div>
                    <strong> {{ "VISIT_TYPE" | translate }}: </strong> <span *ngIf="row.camera.anonymizedPropExists">{{ "ANONYMIZED" | translate }} {{ row.camera.visitType.toLowerCase() | langCode | translate}}</span><span *ngIf="!row.camera.anonymizedPropExists">{{ row.camera.visitType | langCode | translate }}</span>
                    <span class="clear-image-badge" *ngIf="row.camera.anonymizedPropExists && !row.camera.anonymizedStatus"><i class="fa-solid fa-eye"></i> {{"CLEAR_IMAGE_VIEWED" | translate}}</span>
                    <br />
                </div>
            </div>
          </div>
        </td>
      </tr>

      <!-- Detail row -->
    </ng-container>
  </tbody>
</table>
