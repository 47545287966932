import { Component, ContentChild, Input, TemplateRef, ViewChild, SecurityContext } from '@angular/core';
import { HelperService } from 'app/services/helper.service';
import { CrSyncService, CrAlert, CrAlertState } from '../../../services/crsync.service';
import { environment } from 'environments/environment';
import { AlarmService } from 'app/services/alarm.service';
import { Observable, Subscription, noop } from 'rxjs';
import { IAlarmReasonsRequest } from 'app/models/alarm-reasons.model';
import { ApiService } from 'app/services/api.service';
import { MarkAlarmReasonComponent } from './mark-alarm-reason/mark-alarm-reason.component';
import { SafePipe, TelephonifyPipe, NewLineToBreakPipe, PickUpAlertIcon, PickUpActivationName, CalculateAlertTime, AlreadyAdded } from '../../../pipes/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { CrNotesFormComponent } from '../control-room-notes/cr-notes-form/cr-notes-form.component';
import { CrNotesListComponent } from '../control-room-notes/cr-notes-list/cr-notes-list.component';
import { AddNoteComponent } from './add-note/add-note.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AsyncPipe, CommonModule } from '@angular/common';
import { CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { ControlRoomService } from 'app/services/control-room.service';
import { NotificationBadgeComponent } from 'app/components/notifications/notification-badge/notification-badge.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ALERT_WITHOUT_ALARM_REASONS } from 'assets/alarmReasons';

@Component({
    selector: 'control-room-alert-panel-started',
    templateUrl: './control-room-alert-panel-started.component.html',
    styleUrls: ['../control-room.component.css'],
    standalone: true,
    imports: [
      // modules
      CommonModule,
      CdkDropList,
      CdkDrag,
      CdkDragHandle,
      MatTooltipModule,
      TranslateModule,
      // pipes
      AsyncPipe,
      SafePipe,
      TelephonifyPipe,
      NewLineToBreakPipe,
      PickUpAlertIcon,
      PickUpActivationName,
      CalculateAlertTime,
      AlreadyAdded,
      // components
      CrNotesListComponent,
      AddNoteComponent,
      CrNotesFormComponent,
      MarkAlarmReasonComponent,
      NotificationBadgeComponent
    ]
})
export class ControlRoomAlertPanelStartedComponent {

  constructor(
    public helper: HelperService,
    private crSync: CrSyncService,
    public crService: ControlRoomService,
    private alarmService: AlarmService,
    private api: ApiService,
    private sanitizer: DomSanitizer
  ) { }

  // @ViewChild(AlarmReasons) checkboxForm: AlarmReasons;

  checkboxListIsOpen: boolean = false;
  showFurtherActions$: Observable<boolean>;

  // @Input('alertsArray') alerts: CrAlert[];
  // @Input('visits') visits: any;
  // @Input('teamsAlertsArray') teamsAlerts: any;

  get alerts(): CrAlert[]{
    return this.crService.startedAlerts
  }
  get visits() {
    return this.crService.startedVisits
  }
  get teamsAlerts() {
    return this.crService.teamsStartedAlerts
  }

  @Input('showTeamAlerts') showTeamAlerts: boolean;
  videoControllerBaseUrl: string = environment.videoServerBaseUrl;

  // needed to use the enum CrAlertState in HTML template
  emaAlertState = CrAlertState;
  showNoteForm = false;
  @ViewChild(MarkAlarmReasonComponent) MarkAlarmReasonComponent: MarkAlarmReasonComponent;
  fireAlreadyAddedPipe = this.alarmService.checkedTasksLength$
  language: string;
  features: string;

  noteText: string = "";

  ngOnInit() {
    this.language = localStorage.getItem('language')

  }

  handleNoteText(text: string, alertId: string) {
    let ix = this.crService.startedAlerts.findIndex((a) => a.alertId === alertId);
    this.crService.startedAlerts[ix].noteCache = text;
  }

  sanitizeUrl(url: string) {
    return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, url);
  }

  getTeamMemberName(alert: CrAlert): string {
    let member = this.crSync.getTeamMember(alert.startedBy, alert.userId);
    if (member) return member.name || "";
    return "";
  }

  rewriteLanguage(UILanguage: string): string {
    let LangId: string;
    switch (UILanguage) {
      case 'en':
        LangId = 'EN'
        break;
      case 'fi':
        LangId = 'FI'
        break;
      case 'sv':
        LangId = 'SE'
        break;
      case 'ee':
        LangId = 'EE'
        break;
      default:
        LangId = 'EN'
    }
    return LangId
  }

  /**
   * Creates an alarm reasons request object for the API.
   *
   * @param alertId {string}  CrAlert objects alertId.
   * @returns {IAlarmReasonsRequest} - The request object for API.
   */
  createAlarmReasonsRequestObject(alertId: string) {
    const requestObject: IAlarmReasonsRequest = {
      taskId: alertId,
      langId: this.rewriteLanguage(this.language),
      alarmReasons: this.alarmService.getAlarmReasonsForAlert(alertId)
    }
    return requestObject
  }


  /**
   * Completes an alert by
   * sending an alarm reasons request to the API,
   * removing alarm reasons from storage,
   * and after successful call to API by performing additional actions in control room service
   *
   * @param {CrAlert} alert - The alert object to be completed.
   */
  completeAlert(alert: CrAlert) {

    const requestObject = this.createAlarmReasonsRequestObject(alert.alertId)

    this.alarmService.removeAlarmReasonsFromStorage(alert.alertId)

    this.api.setReasonsToAlert(requestObject).subscribe(
      noop,
      noop,
      ()=> {
        this.crSync.completeAlert(alert.userId, alert.alertId)
      }
    )
  }


  /**
   * Sends alarm reasons to the application,
   * removes alarm reasons from storage
   * and after successful call to API performs additional actions in control room service
   *
   * @param {CrAlert} alert - The alert object to be sent to the application.
   */
  sendToApp(alert: CrAlert) {

    const requestObject = this.createAlarmReasonsRequestObject(alert.alertId)

    this.alarmService.removeAlarmReasonsFromStorage(alert.alertId)

    this.api.setReasonsToAlert(requestObject).subscribe(
      noop,
      noop,
      ()=> {
        this.crService.takeAction(alert)
      }
    )
  }

  cancelReservation(alert: CrAlert) {
    // clean alarm reasons which were attached to the alert temporarily
    this.alarmService.deleteAlarmReasonsFromAll(alert.alertId)
    this.alarmService.removeAlarmReasonsFromStorage(alert.alertId)
    this.alarmService.removeFromEnabledAlarms(alert.alertId)

    this.crService.cancelReservation(alert)
  }

  snoozeVisit(visit: any) {
    this.crService.snoozeVisit(visit);
  }

  hideForm() {
    this.showNoteForm = false
  }

  callPatient(baseMISDN: string) {
    if (baseMISDN) {
      window.location.href = "tel:" + baseMISDN;
    }
  }
}
