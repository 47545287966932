import { Injectable } from "@angular/core";
import { CrAlert, CrAlertState, CrSyncService } from "./crsync.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { VideoServerService } from "./videoserver.service";
import { ApiService } from "./api.service";
import { PickUpAlertIcon } from "app/pipes/pipes";
import { ActiveCameraVisit } from "app/models/camerasupervision.model";
import * as moment from "moment";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root",
})
export class ControlRoomService {
  constructor(
    private crSync: CrSyncService,
    private videoServerService: VideoServerService,
    private api: ApiService,
    private pickUpAlertIcon: PickUpAlertIcon,
    private notificationService: NotificationService
  ) {}

  transitionalAlerts: { [alertId: string]: NodeJS.Timeout } = {};

  incomingVisits: any[] = [];
  startedVisits: any[] = [];

  incomingAlerts: any[] = [];
  startedAlerts: CrAlert[] = [];
  forwardedAlerts: any[] = [];
  teamsStartedAlerts: any[] = [];
  teamsForwardedAlerts: any[] = [];

  private _isUserOrAlias(userId: string): boolean {
    return (
      userId === this.crSync.userId ||
      this.crSync.aliases.findIndex((alias) => alias.userId === userId) !== -1
    );
  }

  // completeAlert(userId: string, alertId: string): void {
  //   this.crSync.completeAlert(userId, alertId);
  // }

  takeAction(alert: CrAlert) {
    if (
      alert.state === CrAlertState.Available ||
      alert.state === CrAlertState.Reserved
    ) {
      this.crSync.changeAlertState(
        alert.userId,
        alert.alertId,
        CrAlertState.Started
      );
    } else {
      this.crSync.forwardAlert(alert.userId, alert.alertId);
    }
    alert.transitional = true;
    let timer = setTimeout(() => {
      alert.transitional = false;
    }, 5000);
    this.transitionalAlerts[alert.alertId] = timer;
  }

  cancelReservation(alert: CrAlert) {
    this.crSync.changeAlertState(
      alert.userId,
      alert.alertId,
      CrAlertState.Available
    );
    alert.transitional = true;
    let timer = setTimeout(() => {
      alert.transitional = false;
    }, 5000);
    this.transitionalAlerts[alert.alertId] = timer;
  }

  updateAllAlerts(alerts: CrAlert[]): void {
    // re-fill alert lists
    this.incomingAlerts = [];
    this.startedAlerts = [];
    this.teamsStartedAlerts = [];
    alerts.forEach((alert) => {
      if (alert.state === CrAlertState.Started) {
        if (this._isUserOrAlias(alert.startedBy)) {
          this.startedAlerts.push(alert);
        } else {
          this.teamsStartedAlerts.push(alert);
        }
      } else {
        this.incomingAlerts.push(alert);
      }
    });
    // sort the list so reserved alerts are on top
    this.incomingAlerts.sort((a, b) => {
      return a.reserved === b.reserved ? 0 : a.reserved ? -1 : 1;
    });
    // all alerts are up to date --> remove transitional alerts
    for (const alertId in this.transitionalAlerts) {
      clearTimeout(this.transitionalAlerts[alertId]);
    }
    this.transitionalAlerts = {};
  }

  updateOneAlert(alert: CrAlert) {
    const incomingAlertStates = [CrAlertState.Available, CrAlertState.Reserved];
    if (this.transitionalAlerts[alert.alertId] !== undefined) {
      // getting update for transitional alert --> not transitional anymore
      clearTimeout(this.transitionalAlerts[alert.alertId]);
      delete this.transitionalAlerts[alert.alertId];
    }


    let ix: number = this.incomingAlerts.findIndex(
      (a) => a.alertId === alert.alertId
    );
    if (ix !== -1) {
      if (incomingAlertStates.includes(alert.state)) {
        // set in place
        this.incomingAlerts[ix] = alert;
      } else if (alert.state === CrAlertState.Started) {
        // remove from incoming
        this.incomingAlerts.splice(ix, 1);
        if (this._isUserOrAlias(alert.startedBy)) {
          // started by current user --> put to started
          this.startedAlerts.push(alert);
        } else {
          // started by other user --> put to team's started
          this.teamsStartedAlerts.push(alert);
        }
      }
      return;
    }
    ix = this.startedAlerts.findIndex((a) => a.alertId === alert.alertId);
    var temp = this.startedAlerts.length != 0 ? this.startedAlerts[ix].noteCache: "";
    
    if (ix !== -1) {
      if (incomingAlertStates.includes(alert.state)) {
        // move from started to incoming
        this.incomingAlerts.push(alert);
        this.startedAlerts.splice(ix, 1);
      } else if (alert.state === CrAlertState.Started) {
        if (this._isUserOrAlias(alert.startedBy)) {
          // started by current user --> set in place
          this.startedAlerts[ix] = alert;
        } else {
          // started by other user --> put to team's started
          this.teamsStartedAlerts.push(alert);
          this.startedAlerts.splice(ix, 1);
        }
      }
      if (this.startedAlerts.length != 0) this.startedAlerts[ix].noteCache = temp;
      return;
    }
    ix = this.teamsStartedAlerts.findIndex((a) => a.alertId === alert.alertId);
    if (ix !== -1) {
      if (incomingAlertStates.includes(alert.state)) {
        // move from team's started to incoming
        this.incomingAlerts.push(alert);
        this.teamsStartedAlerts.splice(ix, 1);
      } else if (alert.state === CrAlertState.Started) {
        if (this._isUserOrAlias(alert.startedBy)) {
          // started by current user --> put to started
          this.startedAlerts.push(alert);
          this.teamsStartedAlerts.splice(ix, 1);
        } else {
          // started by other user --> set in place
          this.teamsStartedAlerts[ix] = alert;
        }
      }
      return;
    }
    // this is a new alert
    if (incomingAlertStates.includes(alert.state)) {
      this.incomingAlerts.push(alert);
      this.notificationService.notifyNewAlert(alert.header);
    } else if (
      alert.state === CrAlertState.Started &&
      this._isUserOrAlias(alert.startedBy)
    ) {
      this.startedAlerts.push(alert);
    }
  }

  deleteOneAlert(alertId: string) {
    if (this.transitionalAlerts[alertId] !== undefined) {
      // delete transitional alert
      clearTimeout(this.transitionalAlerts[alertId]);
      delete this.transitionalAlerts[alertId];
    }

    // check all alerts list: if filtered list is shorter, commit to it and return
    let tmp = this.incomingAlerts.filter((a) => a.alertId !== alertId);
    if (tmp.length < this.incomingAlerts.length) {
      this.incomingAlerts = tmp;
      return;
    }
    tmp = this.startedAlerts.filter((a) => a.alertId !== alertId);
    if (tmp.length < this.startedAlerts.length) {
      this.startedAlerts = tmp;
      return;
    }
    tmp = this.teamsStartedAlerts.filter((a) => a.alertId !== alertId);
    if (tmp.length < this.teamsStartedAlerts.length) {
      this.teamsStartedAlerts = tmp;
      return;
    }
    // deleted alert can also be in forwarded alerts if user has dragged
    // it there for forwarding
    this.forwardedAlerts = this.forwardedAlerts.filter(
      (a) => a.alertId !== alertId
    );
  }

  drop(event: CdkDragDrop<CrAlert[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      let alert: CrAlert = event.previousContainer.data[event.previousIndex];
      if (event.container.id === "startedAlertList") {
        let ok = this.crSync.changeAlertState(
          alert.userId,
          alert.alertId,
          CrAlertState.Started
        );
        if (!ok) return;
      } else if (event.container.id === "forwardedAlertList") {
        if (alert.state !== CrAlertState.Started) return;
        this.crSync.forwardAlert(alert.userId, alert.alertId);
      } else {
        this.crSync.changeAlertState(
          alert.userId,
          alert.alertId,
          CrAlertState.Available
        );
      }

      let previousAllowTransition = alert.allowTransition;
      alert.allowTransition = false;
      alert.transitional = true;

      let timer = setTimeout(() => {
        let ix = event.container.data.findIndex(
          (a) => a.alertId === alert.alertId
        );
        if (ix !== -1) {
          event.container.data.splice(ix, 1);
          event.previousContainer.data.push(alert);
        }
        delete this.transitionalAlerts[alert.alertId];
        alert.allowTransition = previousAllowTransition;
        alert.transitional = false;
      }, 5000);
      this.transitionalAlerts[alert.alertId] = timer;

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  getFormattedRemainingTime(seconds: number): string {
    // Display hours and minutes if remaining time is more than a 59 minutes
    if (seconds > 3600 - 60) {
      let h = Math.floor(seconds / 3600);
      let m = Math.ceil((seconds % 3600) / 60);

      // If minutes are 60, add one hour and set minutes to 0 to display correctly
      if (m === 60) {
        h++;
        m = 0;
      }

      return `${h} h ${m} min`;
    }

    // Show only minutes if remaining time is more than a minute
    if (seconds > 60) {
      let m = Math.ceil((seconds % 3600) / 60);
      return `${m} min`;
    }

    // If visit is on grace period (remaining time is negative) display 0 minutes
    if (seconds < 0) {
      return `0 sec`;
    }

    // Display seconds if none of the above matches
    return `${seconds} sec`;
  }

  startVisit(visit: any): void {
    this.videoServerService.startVisit(visit).subscribe((res) => {});
  }

  completeStartedVisit(visit: any): void {
    this.videoServerService.completeVisit(visit).subscribe((res) => {});
  }

  generateAlertFromVisit(visit: any): void {
    this.api.createNewAlert(visit.lyraId, 1, 2).subscribe((res) => {
      this.completeStartedVisit(visit);
    });
  }

  cancelStartedVisit(visit: any): void {
    this.videoServerService.cancelStartedVisit(visit).subscribe((res) => {});
  }

  snoozeVisit(visit: any): void {
    if (visit) {
      this.videoServerService.snoozeVisit(visit).subscribe((res) => { });
    }
  }

  getAlertFaIcon(alert: CrAlert): string {
    switch (alert.deviceType) {
      case "06-2":
      case "33-255":
        return "fa-triangle-exclamation colorRed";
      case "34-1":
      case "34-2":
        return "fa-video colorLightBlue";
      case "89-1":
      case "10-2":
      case "88-1":
        return "fa-fire colorRed";
      case "10-3":
      case "10-5":
      case "10-9":
      case "32-1":
      case "32-3":
        return "fa-door-open colorOrange";
      default:
        return "fa-bell colorYellow";
    }
  }

  notifyAlert(alert: CrAlert) {
    // show notification, if supported and granted
    if ("Notification" in window && Notification.permission === "granted") {
      var icon = this.pickUpAlertIcon.transform(alert);
      const options = {
        icon: icon,
      };
      new Notification(alert.header, options);
    }
    // play audio
    new Audio("/assets/sounds/1.mp3").play();
  }

  updateAllForwardedAlerts(alerts: CrAlert[]) {
    this.forwardedAlerts = [];
    this.teamsForwardedAlerts = [];
    alerts.forEach((alert) => {
      if (this._isUserOrAlias(alert.reRoutedBy)) {
        this.forwardedAlerts.push(alert);
      } else {
        this.teamsForwardedAlerts.push(alert);
      }
    });
  }

  updateOneForwardedAlert(alert: CrAlert) {
    if (this._isUserOrAlias(alert.reRoutedBy)) {
      let ix: number = this.forwardedAlerts.findIndex(
        (a) => a.alertId === alert.alertId
      );
      if (ix !== -1) {
        alert.noteCache = this.forwardedAlerts[ix].noteCache;
        this.forwardedAlerts[ix] = alert;
      } else {
        this.forwardedAlerts.push(alert);
      }
    } else {
      let ix: number = this.teamsForwardedAlerts.findIndex(
        (a) => a.alertId === alert.alertId
      );
      if (ix !== -1) {
        this.teamsForwardedAlerts[ix] = alert;
      } else {
        this.teamsForwardedAlerts.push(alert);
      }
    }
  }

  deleteOneForwardedAlert(alertId: string) {
    this.forwardedAlerts = this.forwardedAlerts.filter(
      (a) => a.alertId !== alertId
    );
    this.teamsForwardedAlerts = this.teamsForwardedAlerts.filter(
      (a) => a.alertId !== alertId
    );
  }

  // it would be good to place into videovisits service

  notifyNewVisits(count: number): void {
    if (count > 0) {
      this.notificationService.notifyNewVisits(count);
    }
  }

  updateCameraVisits(activeVisits: ActiveCameraVisit[]) {
    let newVisitCount = 0;

    activeVisits.forEach((v) => {
      // Find correct alias user from localstorage to get company and location information
      let alias = JSON.parse(
        localStorage.getItem("alertServerUser")
      ).aliases.find((x) => x.companyId === v.companyId);
      let location = alias.locations.find((x) => x.id === v.locationId);

      // Get needed information about the visit object
      var aliasFeatures = JSON.parse(localStorage.getItem('aliasFeatures'));
      let visit = {
        id: v.id,
        type: v.visitType,
        start: v.scheduleItem?.startDate,
        end: v.scheduleItem?.endDate,
        name: v.displayName,
        companyId: v.companyId,
        companyName: alias.companyName,
        locationId: v.locationId,
        locationName: location ? location.name : alias.companyName,
        userGroups: v.scheduleItem?.userGroups,
        rtsp: encodeURIComponent(v.cameras[0]?.rtspUrl),
        lyraId: v.cameras[0]?.lyraId,
        token: v.userToken,
        startedBy: v.startedBy,
        completedBy: v.completedBy ? v.completedBy : "",
        timeRemaining: moment(v.scheduleItem?.endDate).diff(moment(), "seconds"),
        snoozed: v.scheduleItem.snoozed || v.scheduleItem.snoozedDeleteWhenDone,
        snoozable: aliasFeatures.indexOf("SnoozableVisits") !== -1,
        cameraGroupId: v.scheduleItem.cameraGroupId,
        timeZone: v.scheduleItem.time.timeZone,
        gracePeriod: v.scheduleItem.gracePeriod,
        weekminuteStop: v.scheduleItem.stopTime.weekminute
      };

      // Get all user groups from aliases
      let user = JSON.parse(localStorage.getItem("user"));
      let aliasArray: any[] = [];
      if (user.aliasList && user.aliasList.length > 0) {
        user.aliasList.forEach((e) => {
          if (e.userGroups && e.userGroups.length > 0) {
            e.userGroups.forEach((g) => {
              aliasArray.push(g);
            });
          }
        });
      }
      // Check that logged in user is in same group as visit. If not then skip adding visit
      if (!visit.userGroups?.some((x) => aliasArray.includes(x))) {
        return;
      }

      // Check if visit has been started by someone. Place visits not started by anyone to incoming visits. If visit has been started by logged in operator place it in started visits
      if (visit.startedBy === "" && visit.completedBy === "") {
        // Add visit into list of incoming visits if it does not exists there yet
        this.incomingVisits.findIndex((x) => x.id == visit.id) === -1 &&
          this.incomingVisits.push(visit) &&
          newVisitCount++;
        // Make sure that visit does not exists in started visits list either
        let ix = this.startedVisits.findIndex((x) => x.id === visit.id);
        if (ix !== -1) this.startedVisits.splice(ix, 1);
      } else if (visit.startedBy !== "" && visit.completedBy === "") {
        // Check if visit has been started by any one the logged in alias accounts
        let aliases = JSON.parse(
          localStorage.getItem("alertServerUser")
        ).aliases;
        aliases.forEach((a) => {
          if (a.userId === visit.startedBy) {
            this.startedVisits.findIndex((x) => x.id == visit.id) === -1 &&
              this.startedVisits.push(visit);
          }
        });
        // Finally remove the started visit from incoming visits list
        let ix = this.incomingVisits.findIndex((x) => x.id === visit.id);
        if (ix !== -1) this.incomingVisits.splice(ix, 1);
      } else {
        // Remove visit from both lists since it has been already completed or does not belong to any lists for other reasons
        let i = this.incomingVisits.findIndex((x) => x.id === visit.id);
        if (i !== -1) this.incomingVisits.splice(i, 1);

        i = this.startedVisits.findIndex((x) => x.id === visit.id);
        if (i !== -1) this.startedVisits.splice(i, 1);
      }
    });

    // Make sure that both lists contain only valid visits so no visits has been removed
    this.incomingVisits.forEach((iVisit) => {
      let ix = activeVisits.findIndex((x) => x.id === iVisit.id);
      if (ix === -1)
        this.incomingVisits.splice(
          this.incomingVisits.findIndex((x) => x.id === iVisit.id),
          1
        );
    });
    this.startedVisits.forEach((sVisit) => {
      let ix = activeVisits.findIndex((x) => x.id === sVisit.id);
      if (ix === -1)
        this.startedVisits.splice(
          this.startedVisits.findIndex((x) => x.id === sVisit.id),
          1
        );
    });

    // Order both lists based on the remaining time
    this.incomingVisits.sort((a, b) => a.timeRemaining - b.timeRemaining);
    this.startedVisits.sort((a, b) => a.timeRemaining - b.timeRemaining);

    this.notifyNewVisits(newVisitCount);
  }
}
