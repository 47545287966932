import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { ApiService } from 'app/services/api.service';

export interface IEverWatchForm {
  everWatchId: string;
  lyraId: string;
  locationId: string;
  deviceName: string;
}

@Component({
  selector: 'app-everwatch-form',
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, MatTooltipModule, MatFormFieldModule, MatSelectModule, MatOptionModule],
  templateUrl: './everwatch-form.component.html',
  styleUrls: ['./everwatch-form.component.css']
})
export class EverwatchFormComponent {
  @Output() closeEvent = new EventEmitter<void>();
  @Output() savedEvent = new EventEmitter<string>();

  @Input() locations: { id: string, name: string }[];

  form: FormGroup;
  errorMsg: string;
  showSpinner: boolean = false;

  constructor(private api: ApiService) {
    this.form = new FormGroup({
      everWatchId: new FormControl({ value: '', disabled: false }, Validators.required),
      lyraId: new FormControl({ value: '', disabled: true }, Validators.required),
      locationId: new FormControl('', Validators.required),
      deviceName: new FormControl(''),
    });
  }

  verifyId(): void {
    this.showSpinner = true;
    this.errorMsg = "";

    this.api.getEverWatchAvailability(this.form.controls.everWatchId.value).subscribe({
      next: res => {
        if (!res) {
          this.errorMsg = 'ERROR';
          console.error('API error: no response received');
        }
        else if (!res.available) {
          this.errorMsg = 'DEVICE_DONT_EXIST_OR_ALREADY_IN_USE';
        }
        else if (!res.lyraId) {
          this.errorMsg = 'ERROR';
          console.error('API error: Lyra ID was not provided');
        }
        else {
          this.form.controls.lyraId.setValue(res.lyraId);
          this.form.controls.everWatchId.disable();
        }

        this.showSpinner = false;
      },
      error: err => {
        console.error(err.message);
        this.errorMsg = "ERROR";
        this.showSpinner = false;
      }
    })
  }

  submit(): IEverWatchForm {
    if (this.form.invalid) return null;

    const request: IEverWatchForm = {
      everWatchId: this.form.controls.everWatchId.value,
      lyraId: this.form.controls.lyraId.value,
      locationId: this.form.controls.locationId.value,
      deviceName: this.form.controls.deviceName.value
    }

    this.showSpinner = true;

    this.api.addEverWatch(request).subscribe({
      next: () => {
        this.showSpinner = false;
        this.savedEvent.emit(request.lyraId);
      },
      error: err => {
        console.error(err.message);
        this.errorMsg = "ERROR";
        this.showSpinner = false;
      }
    });

    return request;
  }

  clearForm(): void {
    this.form.reset();
    this.form.controls.locationId.setValue('');
    this.form.controls.everWatchId.enable();
  }
}
