<div class="card">
    <div class="card-header-white header-underline">
        <h6 class="white-header-text">
            {{ "NEW_CAMERA_GROUP" | translate }}
        </h6>
        <span class="float-right cursorPointer" (click)="showForm.next(false)">
            <span class="fa-light fa-xmark white-header-xmark-icon"></span>
        </span>
    </div>

    <form class="form" *ngIf="newCameraGroup">
        <div class="form-group row mt-2">
            <label class="col-sm-2 mt-1" for="displayName"><strong>{{ "GROUP_NAME" | translate }}:</strong></label>
            <input
                type="text"
                [(ngModel)]="newCameraGroup.displayName"
                name="displayName"
                id="displayName"
                class="form-control form-control-sm col-sm-4"
                [ngClass]="{ 'is-invalid': !displayName.value, 'is-valid': displayName.value }"
                #displayName="ngModel" />
            <div class="col">
                <p class="float-right">
                    <strong>{{ "TIME_ZONE" | translate }}</strong>: {{ newCameraGroup.timeZone }}
                </p>
            </div>
        </div>

        <div class="form-group row mt-2">
            <label class="col-sm-2 mt-1" for="address"><strong>{{ "ADDRESS" | translate }}:</strong></label>
            <input
                type="text"
                [(ngModel)]="newCameraGroup.address"
                name="address"
                id="address"
                class="form-control form-control-sm col-sm-4"
                [ngClass]="{ 'is-valid': newCameraGroup.address }" />
        </div>

        <div class="form-group row mt-2">
            <label class="col-sm-2 mt-1" for="postCode"><strong>{{ "POSTCODE" | translate }}:</strong></label>
            <input
                type="text"
                [(ngModel)]="newCameraGroup.postCode"
                name="postCode"
                id="postCode"
                class="form-control form-control-sm col-sm-4"
                [ngClass]="{ 'is-valid': newCameraGroup.postCode }" />
        </div>

        <div class="form-group row mt-2">
            <label class="col-sm-2 mt-1" for="city"><strong>{{ "POSTOFFICE" | translate }}:</strong></label>
            <input
                type="text"
                [(ngModel)]="newCameraGroup.postOffice"
                name="city"
                id="city"
                class="form-control form-control-sm col-sm-4"
                [ngClass]="{ 'is-valid': newCameraGroup.postOffice }" />
        </div>

        <div class="form-group row">
            <label class="col-sm-2 mt-1" for="location"><strong>{{ "LOCATION" | translate }}:</strong></label>

            <select
                name="location"
                id="location"
                class="form-control form-control-sm col-sm-4 selectBgImage"
                [ngClass]="{ 'is-invalid': newCameraGroup.locationId.length === 0, 'is-valid': newCameraGroup.locationId.length > 0 }"
                [(ngModel)]="selectedLocation"
                (change)="onlocationSelectChange()">
                <option [value]="undefined" selected>-</option>
                <option
                    *ngFor="let location of locationSelect"
                    [ngValue]="location">
                    {{ location.name }}
                </option>
            </select>
        </div>

        <div class="form-group row">
            <label class="col-sm-2 mt-1" for="accessMode"><strong>{{ "ACCESS_MODE" | translate }}:</strong></label>

            <select
                name="accessMode"
                id="accessMode"
                class="form-control form-control-sm col-sm-4"
                [(ngModel)]="newCameraGroup.accessMode">
                <option [value]="undefined">
                    {{ "SCHEDULED_VISIT" | translate }}
                </option>

                <option value="visitOnAlert">
                    {{ "VISIT_ON_ALERT" | translate }}
                </option>

                <option value="always">
                    {{ "ACCESS_MODE_ALWAYS" | translate }}
                </option>
            </select>
        </div>

        <br />
        <br />

        <div class="section">
            <div class="row mb-5">
                <div class="col-5">
                    <p class="mb-1"><strong>{{ "RECEIVER_TEAMS" | translate }}:</strong></p>
                    <app-multiselect-dropdown
                        class="multiselect-dropdown"
                        [placeholder]="'RECEIVER_TEAM' | translate"
                        [groupedOptions]="receiverTeamSelect"
                        [(selected)]="newCameraGroup.userGroups"
                        [enableSearch]="true"
                        [disabled]="!newCameraGroup.locationId"
                    ></app-multiselect-dropdown>
                </div>

                <div class="col">
                    <ng-container *ngIf="newCameraGroup.userGroups.length > 0">
                        <strong>{{ "SELECTED_RECEIVER_TEAMS" | translate }}:</strong>

                        <div class="bordered-box mt-1">
                          <ul class="mb-0">
                              <li *ngFor="let userGroupId of newCameraGroup.userGroups">
                                  {{ userGroupId | receiverTeamName : receiverTeamSelect }}
                              </li>
                          </ul>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="section pt-2">
            <app-select-cameras
              [cameras]="newCameraGroup.cameras"
              [camerasOfLocation]="camerasOfLocation"
              [cameraList]="cameraList"
              [disabled]="cameraSelectDisabled"
              [selectedLocation]="selectedLocation"
              (cameraSelectChange)="onCameraSelectChange($event)">
            </app-select-cameras>
            <br />
        </div>

        <ng-container *ngIf="newCameraGroup.accessMode === 'visitOnAlert'">
            <div class="section pb-2">
                <div class="row">
                    <div class="col-5">
                        <p class="mb-1"><strong>{{ "DEVICES_TO_TRIGGER_ALERT" | translate }}:</strong></p>
                        <app-multiselect-dropdown
                            class="multiselect-dropdown"
                            [placeholder]="'SELECT_DEVICES' | translate"
                            [options]="alertDeviceSelect"
                            [selected]="selectedAlertDevices"
                            [enableSearch]="true"
                            (selectedChange)="parseSelectedDevices($event)"
                        ></app-multiselect-dropdown>
                    </div>

                    <div class="col">
                        <ng-container *ngIf="visitConfig.alertSources.length > 0">
                            <strong>{{ "SELECTED_DEVICES" | translate }}:</strong>

                            <div class="bordered-box mt-1">
                              <ul class="mb-0">
                                  <li *ngFor="let obj of visitConfig.alertSources">
                                    {{ obj.deviceID | deviceName : alertDeviceSelect }}
                                  </li>
                              </ul>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="section">
                <strong>{{ "LENGTH_OF_VISIT" |translate}}:</strong>

                <div class="row mx-0 mt-4 pb-4">
                    <div class="form-group">
                        <label for="visitTime">
                            <strong>{{ "VISIT_TIME" | translate }}</strong>
                        </label>
                        <div class="d-flex">
                            <input
                                name="visitTime"
                                type="number"
                                [(ngModel)]="visitConfig.options.visitTimeMinutes"
                                (change)="validateVisitTimeInput($event.target.value, 0)"
                                class="form-control form-control-sm"
                                min="0"
                                id="visitTime"
                                style="width: 100px" />

                            <div class="mt-1 mr-2">
                                <div class="row">
                                    <i
                                      class="fa-solid fa-caret-up"
                                      style="margin-bottom: -3px"
                                      (click)="setCount(visitConfig.options.visitTimeMinutes + 1, 0)">
                                    </i>
                                </div>
                                <div class="row">
                                    <i
                                      class="fa-solid fa-caret-down"
                                      (click)="setCount(visitConfig.options.visitTimeMinutes - 1, 0)">
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group ml-5">
                        <label for="visitGraceTime">
                            <strong>{{ "GRACE_TIME" | translate }}</strong>
                        </label>
                        <div class="d-flex">
                            <input
                                type="number"
                                [(ngModel)]="visitConfig.options.visitGraceTimeMinutes"
                                (change)="validateVisitTimeInput($event.target.value, 1)"
                                class="form-control form-control-sm"
                                min="0"
                                id="visitGraceTime"
                                name="visitGraceTime"
                                style="width: 100px" />

                            <div class="mt-1 mr-2">
                                <div class="row">
                                    <i
                                      class="fa-solid fa-caret-up"
                                      style="margin-bottom: -3px"
                                      (click)="setCount(visitConfig.options.visitGraceTimeMinutes + 1, 1)">
                                    </i>
                                </div>
                                <div class="row">
                                    <i
                                      class="fa-solid fa-caret-down"
                                      (click)="setCount(visitConfig.options.visitGraceTimeMinutes - 1, 1)">
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <hr />
        <div
          *ngIf="showSubmitErrorMsg"
          class="alert alert-danger"
          style="width: fit-content"
          role="alert">
            {{ "ADD_CAMERA_GROUP_FAILED_ERROR" | translate }}
        </div>
        <div class="row pb-3" style="width: 100%">
            <div class="col">
                <button
                    type="submit"
                    class="btn btn-primary mr-3"
                    [disabled]="!newCameraGroup.isValid()"
                    appThrottleClick
                    (throttledClick)="submit()">
                    <span class="fa-light fa-floppy-disk"></span>{{ "SAVE" | translate }}
                </button>

                <button
                    type="button"
                    class="btn btn-outline-dark"
                    (click)="showForm.next(false)">
                    <span class="fa-light fa-times"></span>
                    {{ "CANCEL" | translate }}
                </button>
            </div>
        </div>
    </form>
</div>
