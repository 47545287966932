<div class="card stickyCard" style="overflow-x: hidden; max-height: 78vh;">
  <div class="card-header-white header-underline">
    <h6 class="white-header-text" *ngIf="staff.id" style="margin-left: -10px;">{{staff.fullName}} ({{staff.id}})</h6>
    <h6 class="white-header-text" *ngIf="!staff.id" style="margin-left: -10px;">{{'ADD_NEW_STAFF' |translate}}</h6>
    <span class="float-right cursorPointer" (click)="closeStaffCard()">
      <span class="fa-light fa-xmark white-header-xmark-icon"></span>
    </span>
  </div>

  <div>
    <div class="row">
      <div class="col-lg-12">
        <!--Staff information-->
        <div class="m-1" *ngIf="!editMode">
          <div *ngIf="updateSuccess" class="alert alert-success">
            {{'STAFF_UPDATE_SUCCESS' |translate}}
          </div>

          <div class="card card-borders" style="margin-right: 15px; margin-left: 15px;">
            <!--Show unlocking staff field only if staff is locked-->
            <div *ngIf="staff.locked && user.roleLevel >= 100 && showUnlockSection" class="d-flex flex-wrap align-items-center justify-content-between p-3 rounded redBorder">
              <div class="d-flex align-items-center mb-3 mb-lg-0">
                {{'LOCKED_STAFF_INFO' |translate}}
              </div>

              <button
                type="button"
                class="h-50 d-flex align-items-center btn btn-primary"
                (click)="unlockStaff()"
              >
                <i class="fa-solid fa-unlock mr-2"></i>
                {{'UNLOCK_STAFF'| translate}}
              </button>
            </div>

            <table class="table table-borderless table-sm m-2 informationTable">
              <tbody>
                <tr>
                  <td><strong>{{'FIRST_NAME' |translate}}:</strong></td>
                  <td>{{staff.firstName}}</td>
                </tr>
                <tr>
                  <td><strong>{{'LAST_NAME' |translate}}:</strong></td>
                  <td>{{staff.familyName}}</td>
                </tr>
                <tr *ngIf="!staff.identityUsername">
                  <td><strong>{{'USERNAME' |translate}}:</strong></td>
                  <td>{{staff.username}}</td>
                </tr>
                <tr *ngIf="!staff.identityUsername">
                  <td><strong>{{'PASSWORD_LAST_SET' |translate}}:</strong></td>
                  <td>{{staff.passwordChanged |moment: 'l'}}</td>
                </tr>
                <tr>
                  <td><strong>{{'LOCATION' |translate}}:</strong></td>
                  <td>{{staff.location?.name}} ({{staff.locationId}})</td>
                </tr>
                <tr>
                  <td><strong>{{'GSM_NUMBER' |translate}}:</strong></td>
                  <td>{{staff.gsmNumber}}</td>
                </tr>
                <tr>
                  <td><strong>{{'EMAIL' |translate}}:</strong></td>
                  <td>{{staff.email}}</td>
                </tr>
                <tr>
                  <td><strong>{{'SMS_LANGUAGE' |translate}}:</strong></td>
                  <td>{{getLanguageString(staff.language)}}</td>
                </tr>
                <tr>
                  <td><strong>{{'USING_LEVEL' |translate}}:</strong></td>
                  <td>{{getUsingLevelString(staff.usingLevel)}}</td>
                </tr>
                <!--
                <tr *ngIf="isFeatureEnabled('DigitalKey')">
                  <td><strong>{{'ROLE_TYPE' |translate}}:</strong></td>
                  <td>{{staff.roleType}}</td>
                </tr>
                -->
                <tr>
                  <td><strong>{{'RECEIVER_TEAMS' |translate}}:</strong></td>
                  <td>
                    <ng-container *ngFor="let receiverTeam of staff.teams;">
                      <div (click)="openReceiverTeamsPage(receiverTeam.groupId)" class="boldAndBluePointer">
                        {{receiverTeam.groupName}} ({{receiverTeam.groupId}})
                      </div>
                    </ng-container>
                  </td>
                </tr>
                <tr>
                  <td><strong>{{'ALARM_ROUTES' |translate}}:</strong></td>
                  <td>
                    <ng-container *ngFor="let alarmRoute of staff.alarmRoutes;">
                      <div (click)="openAlarmRoutePage(alarmRoute.id)" class="boldAndBluePointer">
                        {{alarmRoute.name}} ({{alarmRoute.id}})
                      </div>
                    </ng-container>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!--Show edit button only for main users and admins. Hide it also if staff does not have ID because new staff is created in that case-->
          <div *ngIf="!(user.roleLevel <= 50 && staff.usingLevel > 50)"class="mt-3" style="padding-bottom: 10px">
            <button
              class="btn btn-outline-primary gray-bg"
              style="margin-left: 15px;"
              (click)="staffEditMode()"
            >
              <span class="fa-light fa-fw fa-pen-to-square"></span> {{'MODIFY' |translate}}
            </button>
          </div>
        </div>

        <!--Staff information edit form-->
        <div *ngIf="editMode">
          <ng-container *ngIf="initialDataLoaded">
            <form [formGroup]="staffEditForm" (ngSubmit)="formSubmitSubject.next()" style="margin-left: 20px; margin-right: 15px;">
              <h5>{{'EDIT_STAFF_INFO' |translate}}</h5>

              <div class="row">
                <div class="divider"></div>

                <div class="col-lg-3">
                  <h6>{{'USER_DETAILS' |translate}}</h6>
                </div>

                <div class="col-lg-9">
                  <!-- Show dropdown to change linked identity for staff-->
                  <div class="form-group" *ngIf="featureEnabled('StrongAuthentication') && user.roleLevel >= 100 && staff.id">
                    <label><strong>{{'STRONG_AUTHENTICATION_USER' |translate}}</strong></label>
                    <mat-form-field class="selector-full-width">
                      <mat-select formControlName="identityUser">
                        <mat-option
                          *ngFor="let identity of identityUsers"
                          [value]="identity.username"
                        >
                          {{identity.firstname}} {{identity.familyName}} ({{identity.username}})
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="form-group" [hidden]="staff.identityUsername">
                    <label><strong>{{'FIRST_NAME' |translate}}</strong></label>
                    <input class="form-control" type="text" placeholder="{{'FIRST_NAME' |translate}}" formControlName="firstName">
                  </div>

                  <div class="form-group" [hidden]="staff.identityUsername">
                    <label><strong>{{'LAST_NAME' |translate}}</strong></label>
                    <input class="form-control" type="text" placeholder="{{'LAST_NAME' |translate}}" formControlName="familyName">
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="divider"></div>

                <div class="col-lg-3">
                  <h6>{{'ACCOUNT_DETAILS' |translate}}</h6>
                </div>

                <div class="col-lg-9">
                  <div class="form-group" [hidden]="staff.identityUsername">
                    <label>
                      <strong>
                        {{'USERNAME' |translate}} <span>*</span>
                      </strong>
                    </label>

                    <p class="text-danger" *ngIf="staffEditForm.get('username').hasError('uniqueUsername')">
                      {{'USERNAME_IN_USE' |translate}}
                    </p>

                    <input
                      class="form-control"
                      type="text"
                      id="staffUsername"
                      placeholder="{{'USERNAME' |translate}}"
                      formControlName="username"
                      (blur)="accountNameBlurMethod()"
                    >
                  </div>

                  <div class="vertical-spacer"></div>

                  <!--Show password new fields if user is adding new staff-->
                  <div class="form-group" *ngIf="!staff.id">
                    <label><strong>{{'PASSWORD' |translate}}</strong></label>

                    <ul
                      *ngIf="enhancedSecurityFeature"
                      [ngClass]="{'text-danger': staffEditForm.get('password').hasError('pattern')}"
                    >
                      <li>{{'ENHANCED_PASSWORD_REQUIREMENTS1' |translate}}</li>
                      <li>{{'ENHANCED_PASSWORD_REQUIREMENTS2' |translate}}</li>
                      <li>{{'ENHANCED_PASSWORD_REQUIREMENTS3' |translate}}</li>
                    </ul>

                    <ng-container *ngIf="!enhancedSecurityFeature">
                        <ng-container [ngSwitch]="true">
                            <p *ngSwitchCase="staffEditForm.get('password').hasError('passwordLength')" class="text-danger">
                                {{'PASSWORD_INFO' | translate}}
                            </p>
                            <p *ngSwitchCase="staffEditForm.get('password').hasError('passwordWhitespace')" class="text-danger">
                                {{ 'WHITESPACES_NOT_ALLOWED' | translate }}
                            </p>
                            <p *ngSwitchDefault>{{'PASSWORD_INFO' | translate}}</p>
                        </ng-container>
                    </ng-container>

                    <div class="form-group mt-3" *ngIf="passwordMisMatch">
                      <span class="text-danger">{{'PASSWORDS_DONT_MATCH' |translate}}</span>
                    </div>

                    <input
                      class="form-control"
                      type="password"
                      placeholder="{{'PASSWORD' |translate}}"
                      formControlName="password"
                    >

                    <input
                      class="form-control mt-2"
                      type="password"
                      placeholder="{{'CONFIRM_PASSWORD' |translate}}"
                      formControlName="confirmPassword"
                    >
                  </div>

                  <!--Show password change fields if user is editing staff or user is not authenticated user-->
                  <div class="form-group" *ngIf="staff.id && !staff.identityUsername">
                    <label><strong>{{'CHANGE_PASSWORD' |translate}}</strong></label>

                    <ul *ngIf="enhancedSecurityFeature" [ngClass]="{'text-danger': staffEditForm.get('password').hasError('pattern')}">
                      <li>{{'ENHANCED_PASSWORD_REQUIREMENTS1' |translate}}</li>
                      <li>{{'ENHANCED_PASSWORD_REQUIREMENTS2' |translate}}</li>
                      <li>{{'ENHANCED_PASSWORD_REQUIREMENTS3' |translate}}</li>
                    </ul>

                    <ng-container *ngIf="!enhancedSecurityFeature">
                        <ng-container [ngSwitch]="true">
                            <p *ngSwitchCase="staffEditForm.get('password').hasError('passwordLength')" class="text-danger">
                                {{'PASSWORD_INFO' | translate}}
                            </p>
                            <p *ngSwitchCase="staffEditForm.get('password').hasError('passwordWhitespace')" class="text-danger">
                                {{ 'WHITESPACES_NOT_ALLOWED' | translate }}
                            </p>
                            <p *ngSwitchDefault>{{'PASSWORD_INFO' | translate}}</p>
                        </ng-container>
                    </ng-container>

                    <div class="form-group mt-3" *ngIf="passwordMisMatch">
                      <span class="text-danger">{{'PASSWORDS_DONT_MATCH' |translate}}</span>
                    </div>

                    <div class="form-group">
                      <input
                        class="form-control"
                        type="password"
                        placeholder="{{'NEW_PASSWORD' |translate}}"
                        formControlName="password"
                      >
                    </div>

                    <div class="form-group">
                      <input
                        class="form-control"
                        type="password"
                        placeholder="{{'CONFIRM_NEW_PASSWORD' |translate}}"
                        formControlName="confirmPassword"
                      >
                    </div>

                    <p *ngIf="staff.passwordChanged !== '0001-01-01T00:00:00Z'" class="text-muted">
                      {{'PASSWORD_LAST_SET' | translate}} {{staff.passwordChanged |moment: 'l'}}
                    </p>
                  </div>

                  <div class="vertical-spacer"></div>
                    <!-- Using level -->
                    <div class="form-group">
                      <label>
                        <strong>
                            {{'USING_LEVEL' |translate}}
                            <span>*</span>
                            <span matTooltip="{{'TOOLTIP_STAFF_USING_LEVEL' |translate}}" class="fa fa-fw fa-info-circle"></span>
                        </strong>
                      </label>

                      <mat-form-field class="selector-full-width">
                        <mat-select [(value)]="staff.usingLevel" formControlName="usingLevel" data-testid="role-level-select">
                          <mat-option [value]="0">
                            {{'STAFF_READONLY' |translate}}
                          </mat-option>

                          <mat-option *ngIf="featureEnabled('EveronMobileApp')" [value]="10">
                            {{'EveronMobileApp' |translate}}
                          </mat-option>

                          <mat-option *ngIf="featureEnabled('AnnaPerenna') || featureEnabled('OnOn')" [value]="40">
                            {{'STAFF_ONON_MAIN_USER' |translate}}
                          </mat-option>

                          <mat-option [value]="50">
                            {{'STAFF_USER' |translate}}
                          </mat-option>

                          <mat-option [value]="100" *ngIf="user.roleLevel >= 100">
                            {{'STAFF_MAIN_USER' |translate}}
                          </mat-option>

                          <!-- Assigning the customer admin role is only allowed by another customer admin or a system admin -->
                          <mat-option [value]="101" data-testid="customer-admin-option" *ngIf="user.roleLevel == 101 || user.roleLevel == 200">
                            {{'STAFF_CUSTOMER_ADMIN' |translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <!-- Opening rights for lock types -->
                    <div class="form-group" *ngIf="isFeatureEnabled('DigitalKey')">
                      <label>
                        <strong>
                          {{'LOCK_OPENING_RIGHT' |translate}}
                          <span matTooltip="{{'ROLE_TYPE_TOOLTIP' |translate}}" class="fa fa-fw fa-info-circle"></span>
                        </strong>
                      </label>

                      <mat-form-field class="selector-full-width">
                        <mat-select formControlName="lockType" multiple>
                          <mat-option
                            *ngFor="let lock of lockTypes"
                            [value]="lock.value"
                            [disabled]="!lock.requirePermission || user.roleLevel <= 50"
                          >
                            {{lock.name | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="divider"></div>

                  <div class="col-lg-3">
                    <h6>{{'ALERT_RECEIVING' |translate}}</h6>
                  </div>

                  <div class="col-lg-9">
                    <div class="form-group">
                      <label>
                        <strong>{{'LOCATION' |translate}}
                          <span>*</span>
                          <span matTooltip="{{'TOOLTIP_STAFF_LOCATION' |translate}}" class="fa fa-fw fa-info-circle"></span>
                        </strong>
                      </label>

                      <mat-form-field class="selector-full-width">
                        <mat-select formControlName="location">
                          <mat-option *ngFor="let location of locations" [value]="location">
                            {{location.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="form-group" *ngIf="staff.id && staffEditForm.value.location && (staffEditForm.value.location.id === user.customerId && user.roleLevel >= 100)">
                      <label>
                        <strong>
                          {{'LOCATION_ACCESS' |translate}}
                          <span matTooltip="{{'LOCATION_ACCESS_INFORMATION' |translate}}" class="fa fa-fw fa-info-circle"></span>
                        </strong>
                      </label>

                      <mat-form-field class="selector-full-width">
                        <mat-select formControlName="accessLocations" multiple>
                            <ng-container *ngFor="let accessLocation of locations">
                                <mat-option [value]="accessLocation.id" *ngIf="!accessLocation.id.startsWith('C0')">
                                    {{accessLocation.name}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="form-group">
                      <label>
                        <strong>
                          {{'RECEIVER_TEAMS' |translate}}
                        </strong>
                      </label>

                      <mat-form-field class="selector-full-width">
                        <mat-select formControlName="receiverTeams" [compareWith]="compareByGroupId" multiple (openedChange)="receiverTeamDropDownOpen = $event">
                          <ng-container *ngIf="availableReceiverTeamLocations && availableReceiverTeamLocations[0]">
                            <mat-optgroup *ngFor="let receiverTeamLocation of availableReceiverTeamLocations" [label]="receiverTeamLocation.locationName">
                              <mat-option
                                *ngFor="let team of receiverTeamLocation.receiverTeams"
                                [value]="team"
                              >
                                {{team.groupName}}
                                <ng-container *ngIf="receiverTeamDropDownOpen">
                                  ({{team.groupId}})
                                </ng-container>
                              </mat-option>
                            </mat-optgroup>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="form-group">
                      <label>
                        <strong>
                          {{'ALARM_ROUTES' |translate}}
                        </strong>
                      </label>

                      <mat-form-field class="selector-full-width">
                        <mat-select
                          formControlName="alarmRoutes"
                          [compareWith]="compareByRouteId"
                          (openedChange)="alarmRouteDropDownOpen = $event"
                          multiple
                        >
                          <ng-container *ngIf="availableAlarmRouteLocations && availableAlarmRouteLocations[0]">
                            <mat-optgroup *ngFor="let alarmRouteLocation of availableAlarmRouteLocations" [label]="alarmRouteLocation.locationName">
                              <mat-option *ngFor="let route of alarmRouteLocation.routes" [value]="route">
                                {{route.name}}
                                <ng-container *ngIf="alarmRouteDropDownOpen">
                                  ({{route.id}})</ng-container>
                                <ng-container *ngIf="route.defaultRoute && alarmRouteDropDownOpen">
                                  - {{'DEFAULT_ROUTE' | translate}}
                                </ng-container>
                              </mat-option>
                            </mat-optgroup>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="form-group">
                      <label>
                        <strong>
                          {{'GSM_NUMBER' |translate}}
                        </strong>
                      </label>

                      <p>{{'GSM_NUMBER_INFO' |translate}}</p>

                      <p *ngIf="staffEditForm.get('gsmNumber').hasError('pattern')" class="text-danger">
                        {{'GSM_INFO' |translate}}
                      </p>

                      <input
                        class="form-control"
                        type="text"
                        placeholder="{{'GSM_NUMBER' |translate}}"
                        formControlName="gsmNumber"
                      >
                    </div>

                    <div class="form-group" [hidden]="staff.identityUsername">
                      <label>
                        <strong>
                          {{'EMAIL' |translate}}
                        </strong>
                      </label>

                      <p>{{'EMAIL_INFO' |translate}}</p>

                      <input
                        class="form-control"
                        type="text"
                        placeholder="{{'EMAIL' |translate}}"
                        formControlName="email"
                      >
                    </div>

                    <div class="form-group">
                      <label>
                        <strong>
                          {{'SMS_LANGUAGE' |translate}}
                          <span>*</span>
                        </strong>
                      </label>

                      <mat-form-field class="selector-full-width">
                        <mat-select formControlName="language">
                          <mat-option value="FI">{{'FINNISH' |translate}}</mat-option>
                          <mat-option value="EN">{{'ENGLISH' |translate}}</mat-option>
                          <mat-option value="SE">{{'SWEDISH' |translate}}</mat-option>
                          <mat-option value="DE">{{'GERMAN' |translate}}</mat-option>
                          <mat-option value="FR">{{'FRENCH' |translate}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <!-- Buttons -->
                <div class="row">
                  <div class="divider"></div>
                </div>

                <div class="form-group" *ngIf="!deleteConfirmation">
                  <button
                    type="submit"
                    class="primaryBtn mr-1"
                    [disabled]="(staffEditForm.invalid || staffEditForm.pristine)"
                  >
                    <span class="fa-solid fa-floppy-disk"></span> {{'SAVE' |translate}}
                  </button>

                  <button type="reset" class="secondaryBtnWhiteBg mr-1" (click)="staffEditMode()">
                    <span class="fa-solid fa-times"></span> {{'CANCEL' |translate}}
                  </button>

                  <button
                    class="warningBtnLessAttention float-right"
                    *ngIf="staff.id && user.roleLevel >= 100" (click)="deleteConfirmation = true"
                  >
                    <span class="fa-solid fa-trash-alt"></span> {{'DELETE' |translate}}
                  </button>

                  <span *ngIf="(staffEditForm.invalid)" class="validationNotice d-block mt-1">
                    {{'ENABLE_SAVE_BTN_NOTIFICATION' | translate}}(*)
                  </span>
                  <br>
                </div>

                <div class="form-group" *ngIf="deleteConfirmation">
                  <p>{{'STAFF_DELETE_CONFIRMATION' |translate: {staff: staff.fullName} }}</p>

                  <button class="warningBtn" (click)="deleteStaff()">
                    <span class="fa-solid fa-trash-alt"></span>
                    {{'DELETE' |translate}}
                  </button>

                  <button class="secondaryBtnWhiteBg ml-2" (click)="deleteConfirmation = false">
                    <span class="fa-solid fa-xmark"></span> {{'CANCEL' |translate}}
                  </button>
                </div>

                <div *ngIf="updateError" class="alert alert-danger">
                  {{'ERROR' |translate}}
                </div>
            </form>
          </ng-container>
          <div *ngIf="!initialDataLoaded">
            <img class="fadeLoader centerLoader logoSmall" src="assets/img/loading_logo.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
