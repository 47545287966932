<div class="row" *ngIf="device">
    <div class="col-lg-12">
        <form [formGroup]="deviceSettingsForm" (ngSubmit)="formSubmitSubject.next()">
            <mat-accordion multi="true">
                <!-- Device bitpara settings group-->
                <mat-expansion-panel *ngIf="device.bitParaSettings">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span><span class="fal fa-fw fa-cogs"></span> {{'PARAMETERS' |translate}}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div formGroupName="devBitParaForm">
                        <div class="row">
                            <div class="col-lg-5" *ngIf="device.bitParaSettings.hasOwnProperty('led')">
                                <div class="form-group">
                                    <label>{{'BITPARA_LED' |translate}}</label>
                                    <section>
                                        <mat-slide-toggle formControlName="led">
                                        </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="form-group">
                                    <label>{{'BITPARA_ALARMBEEP' |translate}}</label>
                                    <section>
                                        <mat-slide-toggle formControlName="alarmBeep">
                                        </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5" *ngIf="device.bitParaSettings.hasOwnProperty('clock')">
                                <div class="form-group">
                                    <label>{{'BITPARA_CLOCK' |translate}}</label>
                                    <section>
                                        <mat-slide-toggle formControlName="clock">
                                        </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                            <div class="col-lg-5" *ngIf="device.bitParaSettings.hasOwnProperty('autoAnswer')">
                                <div class="form-group">
                                    <label>{{'BITPARA_AUTOANSWER' |translate}}</label>
                                    <section>
                                        <mat-slide-toggle formControlName="autoAnswer">
                                        </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                            <div class="col-lg-5" *ngIf="device.bitParaSettings.hasOwnProperty('qmsgEnabled')">
                                <div class="form-group">
                                    <label>QMSG</label>
                                    <section>
                                        <mat-slide-toggle formControlName="qmsgEnabled">
                                        </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5" *ngIf="device.bitParaSettings.hasOwnProperty('presenceMsg')">
                                <div class="form-group">
                                    <label>{{'BITPARA_PRESENCEMSG' |translate}}</label>
                                    <section>
                                        <mat-slide-toggle formControlName="presenceMsg">
                                        </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                            <div class="col-lg-5" *ngIf="device.bitParaSettings.hasOwnProperty('ledPulseAlert')">
                                <div class="form-group">
                                    <label>{{'BITPARA_LEDPULSEALERT' |translate}}</label>
                                    <section>
                                        <mat-slide-toggle formControlName="ledPulseAlert">
                                        </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5" *ngIf="device.bitParaSettings.hasOwnProperty('battLedDisabled')">
                                <div class="form-group">
                                    <label>{{'BITPARA_BATTLED' |translate}}</label>
                                    <section>
                                        <mat-slide-toggle formControlName="battLedDisabled">
                                        </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                            <div class="col-lg-5" *ngIf="device.bitParaSettings.hasOwnProperty('homeCare')">
                                <div class="form-group">
                                    <label>{{'BITPARA_HOMECARE' |translate}}</label>
                                    <section>
                                        <mat-slide-toggle formControlName="homeCare">
                                        </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5" *ngIf="device.bitParaSettings.hasOwnProperty('roamingDisabled')">
                                <div class="form-group">
                                    <label>{{'BITPARA_ROAMING' |translate}}</label>
                                    <section>
                                        <mat-slide-toggle formControlName="roamingDisabled">
                                        </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5" *ngIf="device.bitParaSettings.hasOwnProperty('ringerVolume')">
                                <div class="form-group">
                                    <label>{{'BITPARA_RINGERVOLUME' |translate}}</label>
                                    <section>
                                        <mat-form-field>
                                            <mat-select formControlName="ringerVolume">
                                                <mat-option value="1">{{'RINGER_LOW' |translate}}</mat-option>
                                                <mat-option value="0">{{'RINGER_NORMAL' |translate}}</mat-option>
                                                <mat-option value="2">{{'RINGER_LOUD' |translate}}</mat-option>
                                                <mat-option value="3">{{'RINGER_VERYLOUD' |translate}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </section>
                                </div>
                            </div>
                            <div class="col-lg-5" *ngIf="device.bitParaSettings.hasOwnProperty('clockBrightness')">
                                <div class="form-group">
                                    <label>{{'BITPARA_CLOCK_BRIGHTNESS' |translate}}</label>
                                    <section>
                                        <mat-form-field>
                                            <mat-select formControlName="clockBrightness">
                                                <mat-option value="4">{{'BRIGHTNESS_AUTO' |translate}}</mat-option>
                                                <mat-option value="0">{{'BRIGHTNESS_VERY_LOW' |translate}}</mat-option>
                                                <mat-option value="1">{{'BRIGHTNESS_LOW' |translate}}</mat-option>
                                                <mat-option value="2">{{'BRIGHTNESS_NORMAL' |translate}}</mat-option>
                                                <mat-option value="3">{{'BRIGHTNESS_HIGH' |translate}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
                <!--Network settings for TP202-->
                <mat-expansion-panel *ngIf="showMiscSettings('network')">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span><span class="fal fa-fw fa-wifi"></span> {{'NETWORK_SETTINGS' |translate}}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div formGroupName="devNetworkSettingsForm">
                        <div class="form-group mt-3">
                            <h6>{{'TRANSPORT_PRIORITY' |translate}}</h6>
                            <div class="form-inline">
                                <div class="form-group mr-2">
                                    <span class="mr-2">1.</span>
                                    <mat-form-field>
                                        <mat-select formControlName="transportPriority1">
                                            <mat-option value="0">GSM</mat-option>
                                            <mat-option value="1">Ethernet</mat-option>
                                            <mat-option value="2">WiFi</mat-option>
                                            <mat-option *ngIf="transportPriority1.value === '3'" value="3">-</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="form-group mr-2">
                                    <span class="mr-2">2.</span>

                                    <mat-form-field>
                                        <mat-select formControlName="transportPriority2">
                                            <mat-option value="0" [disabled]="transportPriority1.value === '0'">GSM</mat-option>
                                            <mat-option value="1" [disabled]="transportPriority1.value === '1'">Ethernet</mat-option>
                                            <mat-option value="2" [disabled]="transportPriority1.value === '2'">WiFi</mat-option>
                                            <mat-option value="3">-</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="form-group mr-2">
                                    <span class="mr-2">3.</span>
                                    <mat-form-field>
                                        <mat-select formControlName="transportPriority3">
                                            <mat-option value="0" [disabled]="transportPriority1.value === '0' || transportPriority2.value === '0' || transportPriority2.value === '3'">GSM</mat-option>
                                            <mat-option value="1" [disabled]="transportPriority1.value === '1' || transportPriority2.value === '1' || transportPriority2.value === '3'">Ethernet</mat-option>
                                            <mat-option value="2" [disabled]="transportPriority1.value === '2' || transportPriority2.value === '2' || transportPriority2.value === '3'">WiFi</mat-option>
                                            <mat-option value="3">-</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="showNetworkSettings('0')">
                            <h6>{{'MODEM_SETTINGS' |translate}}</h6>
                            <div class="form-group" *ngIf="device.modemSettings.hasOwnProperty('simNetworkMode')">
                                <label>{{'NETWORK_MODE' |translate}} (SIM)</label>
                                <div class="form-group">
                                    <mat-form-field>
                                        <mat-select formControlName="simNetworkMode">
                                            <mat-option value="5">{{device.deviceType === '31-14' ? 'Auto 4G/3G/2G' : 'Auto 4G/2G'}}</mat-option>
                                            <mat-option value="6">4G</mat-option>
                                            <mat-option value="4" *ngIf="device.deviceType === '31-14'">Auto 4G/3G
                                            </mat-option>
                                            <mat-option value="3" *ngIf="device.deviceType === '31-14'">3G</mat-option>
                                            <mat-option value="2" *ngIf="device.deviceType === '31-14'">Auto 3G/2G
                                            </mat-option>
                                            <mat-option value="1">2G</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="device.modemSettings.hasOwnProperty('eSimNetworkMode')">
                                <label>{{'NETWORK_MODE' |translate}} (eSIM)</label>
                                <div class="form-group">
                                    <mat-form-field>
                                        <mat-select formControlName="eSimNetworkMode">
                                            <mat-option value="5">{{device.deviceType === '31-14' ? 'Auto 4G/3G/2G' : 'Auto 4G/2G'}}</mat-option>
                                            <mat-option value="6">4G</mat-option>
                                            <mat-option value="4" *ngIf="device.deviceType === '31-14'">Auto 4G/3G
                                            </mat-option>
                                            <mat-option value="3" *ngIf="device.deviceType === '31-14'">3G</mat-option>
                                            <mat-option value="2" *ngIf="device.deviceType === '31-14'">Auto 3G/2G
                                            </mat-option>
                                            <mat-option value="1">2G</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="device.deviceType === '31-14' && device.modemSettings.hasOwnProperty('simVoiceDomainPreference')">
                                <label>VoLTE (SIM)</label>
                                <div class="form-group">
                                    <mat-form-field>
                                        <mat-select formControlName="simVoiceDomainPreference">
                                            <mat-option value="3">{{'VOLTE_PsVoiceOnly' | translate}}</mat-option>
                                            <mat-option value="2">{{'VOLTE_PsVoicePreferred' |translate}}</mat-option>
                                            <mat-option value="1">{{'VOLTE_CsVoicePreferred' |translate}}</mat-option>
                                            <mat-option value="0">{{'VOLTE_CsVoiceOnly' |translate}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="device.deviceType === '31-14' && device.modemSettings.hasOwnProperty('eSimVoiceDomainPreference')">
                                <label>VoLTE (eSIM)</label>
                                <div class="form-group">
                                    <mat-form-field>
                                        <mat-select formControlName="eSimVoiceDomainPreference">
                                            <mat-option value="3">{{'VOLTE_PsVoiceOnly' | translate}}</mat-option>
                                            <mat-option value="2">{{'VOLTE_PsVoicePreferred' |translate}}</mat-option>
                                            <mat-option value="1">{{'VOLTE_CsVoicePreferred' |translate}}</mat-option>
                                            <mat-option value="0">{{'VOLTE_CsVoiceOnly' |translate}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="device.modemSettings.hasOwnProperty('simNetworkMode') && device.modemSettings.hasOwnProperty('eSimNetworkMode')">
                                <label>{{'SIM_MODE' |translate}}</label>

                                <div class="form-group">
                                    <mat-form-field>
                                        <mat-select formControlName="simMode">
                                            <mat-option value="3">{{'SIM_PHYSICAL_FIRST' |translate}}</mat-option>
                                            <mat-option value="4">{{'SIM_EMBEDDED_FIRST' |translate}}</mat-option>
                                            <mat-option value="1">{{'FORCE_PHYSICAL' |translate}}</mat-option>
                                            <mat-option value="2">{{'FORCE_EMBEDDED' |translate}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>{{'MODEM_ANT_MODE' |translate}}</label>
                                <div class="form-group">
                                    <mat-form-field>
                                        <mat-select formControlName="modemAntMode">
                                            <mat-option value="1">{{'ANTENNA_INTERNAL' |translate}}</mat-option>
                                            <mat-option value="2">{{'ANTENNA_EXTERNAL' |translate}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <!--Allowed and blocked operator configuration. Displayed only for admins-->
                            <div *ngIf="user.roleLevel === 200">
                                <div *ngIf="device.modemSettings.hasOwnProperty('simNetworkMode')">
                                    <div class="form-group">
                                        <label>{{'ALLOWED_OPERATORS' |translate}} (SIM) <span
                                                class="fa fa-fw fa-info-circle"
                                                matTooltip="{{'OPERATOR_CONFIG_INFO' |translate}}"
                                                [matTooltipPosition]="'above'"></span></label>
                                        <input type="text" class="form-control" formControlName="simAllowedOperators">
                                    </div>
                                    <div class="form-group">
                                        <label>{{'BLOCKED_OPERATORS' |translate}} (SIM) <span
                                                class="fa fa-fw fa-info-circle"
                                                matTooltip="{{'OPERATOR_CONFIG_INFO' |translate}}"
                                                [matTooltipPosition]="'above'"></span></label>
                                        <input type="text" class="form-control" formControlName="simBlockedOperators">
                                    </div>
                                </div>
                                <div *ngIf="device.modemSettings.hasOwnProperty('eSimNetworkMode')">
                                    <div class="form-group">
                                        <label>{{'ALLOWED_OPERATORS' |translate}} (eSIM) <span
                                                class="fa fa-fw fa-info-circle"
                                                matTooltip="{{'OPERATOR_CONFIG_INFO' |translate}}"
                                                [matTooltipPosition]="'above'"></span></label>
                                        <input type="text" class="form-control" formControlName="eSimAllowedOperators">
                                    </div>
                                    <div class="form-group">
                                        <label>{{'BLOCKED_OPERATORS' |translate}} (eSIM) <span
                                                class="fa fa-fw fa-info-circle"
                                                matTooltip="{{'OPERATOR_CONFIG_INFO' |translate}}"
                                                [matTooltipPosition]="'above'"></span></label>
                                        <input type="text" class="form-control" formControlName="eSimBlockedOperators">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="showNetworkSettings('2')">
                            <h6>{{'WIFI_SETTINGS' |translate}}</h6>
                            <div class="form-group">
                                <label>{{'SSID' |translate}}</label>
                                <input type="text" class="form-control col-lg-3" formControlName="wifiSSID">
                            </div>
                            <div class="form-group">
                                <label>{{'PASSWORD' |translate}}</label>
                                <input type="text" class="form-control" formControlName="wifiPassword">
                            </div>
                            <div class="form-group">
                                <label>DHCP</label>
                                <section>
                                    <mat-slide-toggle formControlName="wifiDHCP">
                                    </mat-slide-toggle>
                                </section>
                            </div>
                            <div class="form-row" *ngIf="!deviceSettingsForm.controls.devNetworkSettingsForm.value.wifiDHCP">
                                <div class="form-group col-lg-3">
                                    <label>{{'IP_ADDRESS' |translate}}</label>
                                    <input type="text" class="form-control" formControlName="wifiIPAddress">
                                </div>
                                <div class="form-group col-lg-3">
                                    <label>{{'GATEWAY' |translate}}</label>
                                    <input type="text" class="form-control" formControlName="wifiGateway">
                                </div>
                                <div class="form-group col-lg-3">
                                    <label>{{'SUBNET_MASK' |translate}}</label>
                                    <input type="text" class="form-control" formControlName="wifiSubnetMask">
                                </div>
                            </div>
                        </div>
                        <div *ngIf="showNetworkSettings('1')">
                            <h6>{{'ETHERNET_SETTINGS' |translate}}</h6>
                            <div class="form-group">
                                <label>DHCP</label>
                                <section>
                                    <mat-slide-toggle formControlName="ethernetDHCP">
                                    </mat-slide-toggle>
                                </section>
                            </div>
                            <div class="form-row" *ngIf="!deviceSettingsForm.controls.devNetworkSettingsForm.value.ethernetDHCP">
                                <div class="form-group col-lg-3">
                                    <label>{{'IP_ADDRESS' |translate}}</label>
                                    <input type="text" class="form-control" formControlName="ethernetIPAddress">
                                </div>
                                <div class="form-group col-lg-3">
                                    <label>{{'GATEWAY' |translate}}</label>
                                    <input type="text" class="form-control" formControlName="ethernetGateway">
                                </div>
                                <div class="form-group col-lg-3">
                                    <label>{{'SUBNET_MASK' |translate}}</label>
                                    <input type="text" class="form-control" formControlName="ethernetSubnetMask">
                                </div>
                            </div>
                        </div>
                        <h6>{{'WIFI_HOTSPOT_SETTINGS' |translate}}</h6>
                        <div class="form-group">
                            <label>{{'ENABLED' |translate}}</label>
                            <section>
                                <mat-slide-toggle formControlName="hotspotEnabled">
                                </mat-slide-toggle>
                            </section>
                        </div>
                        <div *ngIf="deviceSettingsForm.controls.devNetworkSettingsForm.value.hotspotEnabled">
                            <div class="form-group">
                                <label>{{'PASSWORD' |translate}}</label>
                                <input type="text" class="form-control col-lg-3" formControlName="hotspotPassword">
                            </div>
                            <div class="form-group">
                                <label>{{'CHANNEL' |translate}}</label>
                                <mat-form-field class="ml-3">
                                    <mat-select formControlName="hotspotChannel">
                                        <mat-option value="1">1</mat-option>
                                        <mat-option value="2">2</mat-option>
                                        <mat-option value="3">3</mat-option>
                                        <mat-option value="4">4</mat-option>
                                        <mat-option value="5">5</mat-option>
                                        <mat-option value="6">6</mat-option>
                                        <mat-option value="7">7</mat-option>
                                        <mat-option value="8">8</mat-option>
                                        <mat-option value="9">9</mat-option>
                                        <mat-option value="10">10</mat-option>
                                        <mat-option value="11">11</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-lg-3">
                                    <label>{{'IP_ADDRESS' |translate}}</label>
                                    <input type="text" class="form-control" formControlName="hotspotIpAddress">
                                </div>
                                <div class="form-group col-lg-3">
                                    <label>{{'GATEWAY' |translate}}</label>
                                    <input type="text" class="form-control" formControlName="hotspotGateway">
                                </div>
                                <div class="form-group col-lg-3">
                                    <label>{{'SUBNET_MASK' |translate}}</label>
                                    <input type="text" class="form-control" formControlName="hotspotSubnetMask">
                                </div>
                            </div>
                            <div class="form-group">
                                <label>{{'DHCP_SERVER' |translate}}</label>
                                <section>
                                    <mat-slide-toggle formControlName="hotspotDHCPServer">
                                    </mat-slide-toggle>
                                </section>
                            </div>
                            <div class="form-row" *ngIf="deviceSettingsForm.controls.devNetworkSettingsForm.value.hotspotDHCPServer">
                                <div class="form-group col-lg-3">
                                    <label>{{'IP_RANGE_START' |translate}}</label>
                                    <input type="text" class="form-control" formControlName="hotspotDHCPStart">
                                </div>
                                <div class="form-group col-lg-3">
                                    <label>{{'IP_RANGE_END' |translate}}</label>
                                    <input type="text" class="form-control" formControlName="hotspotDHCPEnd">
                                </div>
                            </div>
                        </div>
                        <h6>VPN</h6>
                        <div class="form-group">
                            <label>{{'ENABLED' |translate}}</label>
                            <section>
                                <mat-slide-toggle formControlName="vpnEnabled">
                                </mat-slide-toggle>
                            </section>
                        </div>
                        <div *ngIf="deviceSettingsForm.controls.devNetworkSettingsForm.value.vpnEnabled">
                            <div class="form-group">
                                <label>Server 1</label>
                                <input type="text" class="form-control" formControlName="vpnServer1">
                            </div>
                            <div class="form-group">
                                <label>Server 2</label>
                                <input type="text" class="form-control" formControlName="vpnServer2">
                            </div>
                            <div class="form-group">
                                <label>DHCP</label>
                                <section>
                                    <mat-slide-toggle formControlName="vpnDhcp">
                                    </mat-slide-toggle>
                                </section>
                            </div>
                            <div class="form-row" *ngIf="!deviceSettingsForm.controls.devNetworkSettingsForm.value.vpnDhcp">
                                <div class="form-group col-lg-3">
                                    <label>{{'IP_ADDRESS' |translate}}</label>
                                    <input type="text" class="form-control" formControlName="vpnIPAddress">
                                </div>
                                <div class="form-group col-lg-3">
                                    <label>{{'GATEWAY' |translate}}</label>
                                    <input type="text" class="form-control" formControlName="vpnGateway">
                                </div>
                                <div class="form-group col-lg-3">
                                    <label>{{'SUBNET_MASK' |translate}}</label>
                                    <input type="text" class="form-control" formControlName="vpnSubnetMask">
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
                <!--Bluetooth settings -->
                <mat-expansion-panel *ngIf="showMiscSettings('bluetoothSettings')">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span><span class="fab fa-fw fa-bluetooth-b"></span> Bluetooth</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div formGroupName="bluetoothSettingsFormGroup">
                        <div class="form-group">
                            <label>{{'ENABLED' |translate}}</label>
                            <section>
                                <mat-slide-toggle formControlName="bluetoothEnabled">
                                </mat-slide-toggle>
                            </section>
                        </div>
                    </div>
                </mat-expansion-panel>
                <!--Terminal parameters -->
                <mat-expansion-panel *ngIf="showMiscSettings('termParams')">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span><span class="fal fa-fw fa-cogs"></span> {{'PARAMETERS' |translate}}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div formGroupName="terminalParameterFormGroup">
                        <div class="row">
                            <div class="col-lg-5">
                                <div class="form-group">
                                    <label>{{'TERM_PARAM_LOCK_ESCORT' |translate}} (PL-100KG)</label>
                                    <section>
                                        <mat-slide-toggle formControlName="lockEscort">
                                        </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="form-group" *ngIf="user.roleLevel > 100">
                                    <label>{{'TERM_PARAM_FALLING_DETECTION' |translate}}</label>
                                    <section>
                                        <mat-slide-toggle formControlName="fallingDetection" [disabled]="deviceSettingsForm.controls.terminalParameterFormGroup.value.lockEscort">
                                        </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5">
                                <div class="form-group" *ngIf="user.roleLevel > 100">
                                    <label>{{'TERM_PARAM_MOVEMENT_MONITORING' |translate}}</label>
                                    <section>
                                        <mat-slide-toggle formControlName="movementMonitoring" [disabled]="deviceSettingsForm.controls.terminalParameterFormGroup.value.lockEscort">
                                        </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="form-group" *ngIf="user.roleLevel > 100">
                                    <label>{{'TERM_PARAM_ACCESS_CONTROL' |translate}}</label>
                                    <section>
                                        <mat-slide-toggle formControlName="accessControl" [disabled]="deviceSettingsForm.controls.terminalParameterFormGroup.value.lockEscort">
                                        </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-5">
                                <div class="form-group" *ngIf="user.roleLevel > 100">
                                    <label>{{'TERM_PARAM_LONG_PRESS' |translate}}</label>
                                    <section>
                                        <mat-slide-toggle formControlName="longPress">
                                        </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
                <!--Misc settings group-->
                <mat-expansion-panel *ngIf="showMiscSettings('all')">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span><span class="fal fa-fw fa-folders"></span> {{'ADDITIONAL_SETTINGS' |translate}}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div formGroupName="devMiscSettingsForm">
                        <div class="form-group" *ngIf="showMiscSettings('movement')">
                            <label for="movementTimeLimitInput">{{'NON_MOVEMENT_ALARM_TIMELIMIT' |translate}}<br><span
                                    [ngClass]="{'text-danger': deviceSettingsForm.controls.devMiscSettingsForm.get('limit').hasError('pattern')}">{{'TIMELIMIT_INFO_NO_DISABLE'
                                    |translate}}</span></label>
                            <input type="text" class="form-control mr-2 col-sm-3" formControlName="limit" id="movementTimeLimitInput">
                        </div>
                        <div class="form-group" *ngIf="showMiscSettings('connTimeLim')">
                            <label for="connTimeLimitInput">{{'CONNECTION_TIME_LIMIT' |translate}} <span
                                    class="fa fa-fw fa-info-circle"
                                    matTooltip="{{'CONNECTION_TIME_LIMIT_HELP' |translate}}"></span><br><span
                                    [ngClass]="{'text-danger': deviceSettingsForm.controls.devMiscSettingsForm.get('connTimeLimit').hasError('pattern')}">{{'TIMELIMIT_INFO'
                                    |translate}}</span></label>
                            <input type="text" class="form-control mr-2 col-sm-3" formControlName="connTimeLimit" id="connTimeLimitInput">
                        </div>
                        <div class="form-group" *ngIf="showMiscSettings('activityMonitoring')">
                            <label>{{'NAV_ACTIVITY' |translate}}</label>
                            <section>
                                <mat-slide-toggle formControlName="activityMonitoring">
                                </mat-slide-toggle>
                            </section>
                        </div>
                    </div>
                    <!--Additional settings for TP202-->
                    <div formGroupName="tp202AdditionalForm" *ngIf="showMiscSettings('tp202_additional')">
                        <hr>
                        <div class="form-group" *ngIf="user.roleLevel === 200">
                            <label>{{'DTMF_CODE' |translate}}</label>
                            <input type="text" class="form-control col-lg-3" formControlName="dtmfCode">
                        </div>
                        <hr>
                        <div class="form-group" *ngIf="user.roleLevel >= 100 && showMiscSettings('tp202_ac_power_alert_delay')">
                            <label>{{'AC_POWER_ALERT_DELAY' |translate}}</label>
                            <input type="text" class="form-control col-lg-3" formControlName="delayACPowerAlert">
                        </div>
                        <hr>
                        <div class="form-group" *ngIf="user.roleLevel > 100">
                            <label>{{'MAX_CALL_DURATION' |translate}}</label>
                            <input type="text" class="form-control col-lg-3" formControlName="maxCallDuration">
                        </div>
                        <hr>
                        <div class="form-group" *ngIf="user.roleLevel > 100">
                            <label>{{'MAX_CALL_DURATION_EXTENDED' |translate}}</label>
                            <input type="text" class="form-control col-lg-3" formControlName="maxCallDurationExtended">
                        </div>
                        <hr>
                        <div class="form-group" *ngIf="user.roleLevel === 200">
                            <label>{{'IGNORE_AUTO_ANSWER_NUMBERS' |translate}}</label>
                            <ul class="ignoreAutoAnswerList">
                                <li *ngFor="let number of deviceSettingsForm.controls.tp202AdditionalForm.value.ignoreAutoAnswer; let i = index">
                                    <span class="mr-3 fal fa-fw fa-trash-alt cursorPointer" (click)="deviceSettingsForm.controls.tp202AdditionalForm.value.ignoreAutoAnswer.splice(i, 1);this.deviceSettingsForm.controls.tp202AdditionalForm.markAsDirty();"></span>                                    {{number}}
                                </li>
                            </ul>
                            <div class="form-inline">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="{{'ADD_NEW' |translate}}" #newAutoIgnoreNumber name="newAutoIgnoreNumber">
                                </div>
                                <div class="form-group ml-2">
                                    <button type="button" class="btn btn-primary" (click)="deviceSettingsForm.controls.tp202AdditionalForm.value.ignoreAutoAnswer.push(newAutoIgnoreNumber.value); newAutoIgnoreNumber.value = '';this.deviceSettingsForm.controls.tp202AdditionalForm.markAsDirty();"><span
                                            class="fal fa-fw fa-plus"></span></button>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="form-group" *ngIf="device.hasOwnProperty('iamOk') && user.roleLevel >= 100">
                            <label>{{'IAM_OK_ENABLED' |translate}}</label>
                            <section>
                                <mat-slide-toggle formControlName="iAmOkEnabled">
                                </mat-slide-toggle>
                            </section>
                        </div>
                        <div class="form-row" *ngIf="deviceSettingsForm.controls.tp202AdditionalForm.value.iAmOkEnabled">
                            <div class="col">
                                <label>{{'REPORT_START_TIME' |translate}}</label>
                                <input class="form-control form-control-sm" [ngxTimepicker]="iAmOkStartTime" [format]="24" [name]="iAmOkStartTime" formControlName="iAmOkStartTime">
                                <ngx-material-timepicker #iAmOkStartTime></ngx-material-timepicker>
                            </div>
                            <div class="col">
                                <label>{{'REPORT_END_TIME' |translate}}</label>
                                <input class="form-control form-control-sm" [ngxTimepicker]="iAmOkEndTime" [format]="24" [name]="iAmOkEndTime" formControlName="iAmOkEndTime">
                                <ngx-material-timepicker #iAmOkEndTime></ngx-material-timepicker>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <div class="mt-3">
                <div class="alert alert-success" *ngIf="saveSuccess">
                    {{'DEVICE_INFORMATION_UPDATED' |translate}}
                </div>
                <div class="alert alert-danger" *ngIf="saveError">
                    {{'ERROR' |translate}}
                </div>
            </div>

            <button [disabled]="deviceSettingsForm.pristine || deviceSettingsForm.controls.devMiscSettingsForm.invalid" type="submit" data-testid="submit-btn" class="btn btn-outline-primary gray-bg">
                {{'SAVE' |translate}}
            </button>
        </form>
        <div *ngIf="showLockBeaconSettings()">
            <lock-beacon-settings [device]="device"></lock-beacon-settings>
        </div>
    </div>
</div>
