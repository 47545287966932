import { Component, OnInit } from "@angular/core";
import { VideoServerService } from "app/services/videoserver.service";
import { CookieService } from "ngx-cookie-service";
import * as moment from "moment-timezone";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { DateAdapter } from "@angular/material/core";
import { CameraVisitIconPipe, FunctionPipe, MomentPipe } from "../../pipes/pipes";
import { RouterLink } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { ApiService } from "app/services/api.service";
import { IMultiSelectOption, NgxBootstrapMultiselectModule } from "ngx-bootstrap-multiselect";
import { CameraVisitLocationNamePipe } from "app/components/camera-supervision/camera-supervision-pipes";
import { FilterOptions, CameraSupervisionFilter, HelperService, UiLang } from "app/services/helper.service";
import { ActiveCameraVisit, CameraVisit } from "app/models/camerasupervision.model";
import { Observable } from "rxjs";
import { MyDateAdapter } from "app/shared/my-date-adapter.shared";
import { WEEKDAY_OPTIONS } from "./create-new-visit/create-new-visit.component";

/**
 * Helpers for the CameraSupervisionComponent
 */
const sortVisitsByStartTime = (visitA: CameraVisit, visitB: CameraVisit): number => {
  return (
    new Date(visitA.startDate).getTime() -
    new Date(visitB.startDate).getTime()
  );
}

const sortByNameDesc = (a: any, b: any) => {
  return a.name > b.name;
}

const formatTime = (timeString: string): string => {
  // Convert 24:xx to 00:xx
  return timeString.startsWith("24") ? "00" + timeString.substring(2) : timeString;
}

interface IWeekday {
  title: string;
  isChecked: boolean;
  value: number;
}

@Component({
  selector: "camera-supervision",
  templateUrl: "./camera-supervision.component.html",
  styleUrls: ["./camera-supervision.component.css"],
  standalone: true,
  imports: [
    NgIf,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatDatepickerModule,
    NgFor,
    MatCheckboxModule,
    MatButtonModule,
    RouterLink,
    NgClass,
    TranslateModule,
    MomentPipe,
    NgxBootstrapMultiselectModule,
    FunctionPipe,
    CameraVisitLocationNamePipe,
    CameraVisitIconPipe
  ],
  providers: [
    { provide: DateAdapter, useClass: MyDateAdapter },
  ],
})
export class CameraSupervisionComponent implements OnInit {
  constructor(
    private videoServer: VideoServerService,
    private api: ApiService,
    private cookies: CookieService,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private helper: HelperService
  ) { }

  user: any;

  allVisits: CameraVisit[];
  allVisitsOriginal: CameraVisit[];
  futureVisits: CameraVisit[];
  activeVisits: ActiveCameraVisit[];
  filterMinDate: Date = moment().toDate();
  filterMaxDate: Date = moment().add(6, "d").toDate();

  // Date filters
  filterStartDay: Date = moment().toDate();
  filterEndDay: Date = moment().add(6, "d").toDate();

  // Time filters
  filterStartTime: string = "00:00";
  filterEndTime: string = "23:59";

  advancedFilter: CameraSupervisionFilter = {
    settings: {
      enableSearch: true,
      showUncheckAll: true,
      dynamicTitleMaxItems: 0,
      checkedStyle: "fontawesome",
      containerClasses: "fullWidth",
      buttonClasses: "btn btn-outline-dark fullWidth",
    },
    location: new FilterOptions(),
    cameraGroup: new FilterOptions(),
    receiverGroup: new FilterOptions(),
    alertRoute: new FilterOptions(),
  };

  weekDayFilterList: IWeekday[] = [
    { title: "MONDAY_SHORT", isChecked: false, value: 1 },
    { title: "TUESDAY_SHORT", isChecked: false, value: 2 },
    { title: "WEDNESDAY_SHORT", isChecked: false, value: 3 },
    { title: "THURSDAY_SHORT", isChecked: false, value: 4 },
    { title: "FRIDAY_SHORT", isChecked: false, value: 5 },
    { title: "SATURDAY_SHORT", isChecked: false, value: 6 },
    { title: "SUNDAY_SHORT", isChecked: false, value: 7 },
  ];

  /**
   * Parse advanced filter options
   */
  parseFilterOptionsFromVisits(visits: CameraVisit[]): void {
    const cameraGroupOptions = this.parseCameraGroupOptions(visits);

    // Empty the filter list temporarily to update option list
    this.advancedFilter.cameraGroup.filterList = [];
    this.advancedFilter.cameraGroup.filterList = cameraGroupOptions;
  }

  /**
   * Parse camera group filter options from visit list
   */
  parseCameraGroupOptions(visits: CameraVisit[]): IMultiSelectOption[] {
    const parsedList = [];
    visits.forEach((visit?) => {
      const exists = parsedList.some((obj) => obj.id === visit.cameraGroupId);
      if (!exists && visit) {
        parsedList.push({
          id: visit.cameraGroupId,
          name: visit.cameraGroupName,
        });
      }
    });
    return parsedList.sort((a, b) => {
      return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
    });
  }

  getAdvancedFilterTranslations(): void {
    const commonTexts = {
      checkAll: this.translate.instant("FILTER_CHECK_ALL"),
      uncheckAll: this.translate.instant("FILTER_UNCHECK_ALL"),
      checked: this.translate.instant("FILTER_CHECKED"),
      checkedPlural: this.translate.instant("FILTER_CHECKED"),
      searchPlaceholder: this.translate.instant("SEARCH"),
      allSelected: this.translate.instant("FILTER_ALL_SELECTED"),
    };

    this.advancedFilter.alertRoute.texts = Object.assign(
      {},
      commonTexts,
      { defaultTitle: this.translate.instant("ALARM_ROUTING") }
    );
    this.advancedFilter.location.texts = Object.assign(
      {},
      commonTexts,
      { defaultTitle: this.translate.instant("LOCATION") }
    );
    this.advancedFilter.cameraGroup.texts = Object.assign(
      {},
      commonTexts,
      { defaultTitle: this.translate.instant("CAMERA_GROUP_NAME") }
    );
    this.advancedFilter.receiverGroup.texts = Object.assign(
      {},
      commonTexts,
      { defaultTitle: this.translate.instant("RECEIVER_GROUP") }
    );
  }

  getCameraVisitAdvancedFilters(): Observable<any> {
    return this.api.getReportFilteringOptions()
  }

  getVisitDurationTimes(visit: CameraVisit): string {
    let startTime = moment(visit.startDate).format("kk:mm");
    let endTime = moment(visit.startDate).add(visit.duration, "minutes").format("kk:mm");

    // Convert 24:xx to 00:xx
    startTime = formatTime(startTime);
    endTime = formatTime(endTime);

    return `${startTime} - ${endTime}`;
  }

  getUserGroupName(groupId: string, list: IMultiSelectOption[]): string {
    let name = groupId;
    const listItem = list.find(x => x.id === groupId);
    if (listItem) {
      name = listItem.name;
    }
    return name;
  }

  getLocationId(serverIdLocationId: string): string {
    return serverIdLocationId.slice(-8);
  }

  mergeCameraVisits(): any {
    this.addVisitsThatEndedToday();
    // Make deep copy from future visits
    this.allVisits = JSON.parse(JSON.stringify(this.futureVisits));
    this.activeVisits.forEach((e) => {
      let visitData: CameraVisit = e.scheduleItem;
      visitData.activeVisit = true;
      visitData.visitType = e.visitType;
      this.allVisits.push(visitData);
    });
    this.allVisits = this.removeTempVisits(this.allVisits).sort(sortVisitsByStartTime);
    this.allVisitsOriginal = JSON.parse(JSON.stringify(this.allVisits));
  }

  clearFilters(): void {
    this.filterStartDay = moment().toDate();
    this.filterEndDay = moment().add(6, "d").toDate();
    this.filterStartTime = "00:00";
    this.filterEndTime = "23:59";
    this.weekDayFilterList.forEach((day) => (day.isChecked = false));
    this.advancedFilter.alertRoute.selectedList = [];
    this.advancedFilter.cameraGroup.selectedList = [];
    this.advancedFilter.location.selectedList = [];
    this.advancedFilter.receiverGroup.selectedList = [];
    this.getAllCameraVisits();
  }

  /**
   * Get total minutes of the day time
   * @param {string} time Time string in format HH:mm
   * @returns number of minutes
   */
  convertHhmmToMinutes(time: string): number {
    const [hours, minutes] = time.split(":");
    const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
    return totalMinutes;
  }

  applyFilters(): void {
    let filteredVisits = this.filterTime(this.allVisitsOriginal);
    filteredVisits = this.applyAdvancedFilters(filteredVisits);
    filteredVisits = this.filterWeekDay(filteredVisits);
    filteredVisits = this.filterDate(filteredVisits);
    this.allVisits = filteredVisits;
  }

  applyAdvancedFilters(cameraVisits: CameraVisit[]): CameraVisit[] {
    const locations = this.advancedFilter.location.selectedList ?? [];
    const cameraGroups = this.advancedFilter.cameraGroup.selectedList ?? [];
    const receiverGroups = this.advancedFilter.receiverGroup.selectedList ?? [];
    //const alertRoutes = this.advancedFilter.alertRoute.selectedList ?? [];

    const selectCount = locations.length + cameraGroups.length + receiverGroups.length;

    if (selectCount > 0) {
      cameraVisits = cameraVisits.filter((visit) => {
        return (
          locations.includes(visit.locationId?.slice(-8)) ||
          cameraGroups.includes(visit.cameraGroupId) ||
          receiverGroups.some(id => visit.userGroups?.some(groupId => groupId === id))
        );
      });
    }
    return cameraVisits;
  }

  filterTime(cameraVisits: CameraVisit[]): CameraVisit[] {
    const filterStartMinutes = this.convertHhmmToMinutes(this.filterStartTime);
    const filterEndMinutes = this.convertHhmmToMinutes(this.filterEndTime);

    // Don't filter visits if start time is 00:00 and end time is 23:59
    if (filterStartMinutes === 0 && filterEndMinutes === 1439) {
      return cameraVisits;
    }

    return cameraVisits.filter(visit => {
      const visitStartTimeString = formatTime(moment(visit.startDate).format("kk:mm"));
      const visitEndTimeString = formatTime(moment(visit.startDate).add(visit.duration, "minutes").format("kk:mm"));

      const visitStartMinutes = this.convertHhmmToMinutes(visitStartTimeString);
      const visitEndMinutes = this.convertHhmmToMinutes(visitEndTimeString);

      return this.isWithinFilterRange(visitStartMinutes, visitEndMinutes, filterStartMinutes, filterEndMinutes);
    });
  }

  onDateChanged(): void {

    this.futureVisits = this.allVisitsOriginal;

    this.mergeCameraVisits();

    // Filter data based on the selected UI filters
    this.applyFilters();

    this.parseFilterOptionsFromVisits(this.allVisits);


  }

  filterWeekDay(cameraVisits: CameraVisit[]): CameraVisit[] {
    const checkedDays = this.weekDayFilterList
      .filter((day) => day.isChecked)
      .map((day) => day.value);

    if (checkedDays.length > 0) {
      cameraVisits = cameraVisits.filter((visit) =>
        checkedDays.includes(visit.time.day)
      );
    }
    return cameraVisits;
  }

  filterDate(cameraVisits: CameraVisit[]): CameraVisit[] {
    const startDate = moment(this.filterStartDay).startOf("day").toDate();
    const endDate = moment(this.filterEndDay).endOf("day").toDate();
    cameraVisits = cameraVisits?cameraVisits.filter((visit) => {
      return (
        new Date(visit.startDate) >= startDate &&
        new Date(visit.startDate) <= endDate
      );
    }) : cameraVisits;
    return cameraVisits;
  }

  deleteCameraVisit(visitToDelete: CameraVisit): void {
    this.videoServer
      .deleteVisit(visitToDelete.cameraGroupId, visitToDelete.id)
      .subscribe(() => {
        this.allVisits = this.allVisits.filter((visit) => visit.id !== visitToDelete.id);
        // Remove visit from original list as well, otherwise it will be shown again when filters are applied
        this.allVisitsOriginal = this.allVisitsOriginal.filter((visit) => visit.id !== visitToDelete.id);
        this.logCameraVisitDeletion(visitToDelete);
      });
  }

  logCameraVisitDeletion(visit: CameraVisit): void {
    const timeSpanMmHh = this.getVisitDurationTimes(visit);

    const weekdays = WEEKDAY_OPTIONS
      .filter((day) => {
        const dayIndex = new Date(visit.startDate).getDay();
        const adjustedDayIndex = (dayIndex === 0) ? 7 : dayIndex;
        return day.id === adjustedDayIndex;
      })
      .map((day) => day.name);

    this.api.logScheduledVisitActivity({
      id: visit.cameraGroupId,
      groupName: visit.cameraGroupName,
      action: "Delete",
      weekdayTranslationCodes: weekdays,
      startTime: timeSpanMmHh.split("-")[0]?.trim(),
      endTime: timeSpanMmHh.split("-")[1]?.trim(),
      extendedVisitTimeMinutes: visit.gracePeriod,
    }).subscribe();

  }

  addVisitsThatEndedToday(): void {
    var vc = this.futureVisits.length;
    for (var i = 0; i < vc; i++) {
      if (new Date(this.futureVisits[i].startDate).getDate() == moment().add(7, "d").toDate().getDate()) {
        if (this.futureVisits[i].stopTime.hour < new Date().getHours() || this.futureVisits[i].stopTime.hour == new Date().getHours() && this.futureVisits[i].stopTime.minute < new Date().getMinutes()) {
          this.futureVisits[i].startDate = new Date(new Date(this.futureVisits[i].startDate).setDate(new Date(this.futureVisits[i].startDate).getDate() - 7));
        }
      }
    }
  }

  getAllCameraVisits(): void {
    this.videoServer.getAllCameraVisits("10079").subscribe((res) => {
      if (res)
        this.futureVisits = res.map((x: any) => ({
          ...x,
          visitType: "TYPE_SCHEDULED_VISIT",
        }));
      this.videoServer.getActiveCameraVisits().subscribe((res) => {
        if (res)
          this.activeVisits = res.map((x: any) => ({
            ...x,
            visitType: "TYPE_SCHEDULED_VISIT",
          }));

        this.mergeCameraVisits();

        this.parseFilterOptionsFromVisits(this.allVisitsOriginal);
      });
    });
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("user"));
    const language = localStorage.getItem("language") as UiLang;
    this.helper.setDateAdapterLocale(language, this.dateAdapter);
    this.getAdvancedFilterTranslations();

    this.getCameraVisitAdvancedFilters().subscribe((res) => {
      // Make login first to video server if token not found
      if (!this.cookies.get("videoserver-session-token")) {
        this.videoServer.login().subscribe((res) => {
          // Set Video server API token into cookies
          this.cookies.set(
            "videoserver-session-token",
            "Bearer " + res.token,
            undefined,
            "/",
            undefined,
            false,
            "Lax"
          );
          this.getAllCameraVisits();
        });
      } else {
        this.getAllCameraVisits();
      }
      this.advancedFilter.alertRoute.filterList = res.alertRoutes.sort(sortByNameDesc);
      this.advancedFilter.location.filterList = res.locations.sort(sortByNameDesc);
      this.advancedFilter.receiverGroup.filterList = res.receiverGroups.sort(sortByNameDesc);

      // Get customer information
      const jsonString = localStorage.getItem("customerInfo");

      if (jsonString) {
        const customerInfo = JSON.parse(jsonString);
        // Add customer to location filters
        this.advancedFilter.location.filterList.unshift({
          id: customerInfo.customerId,
          name: customerInfo.name + " (" + customerInfo.customerId + ")",
        })
      }
    });
  }

  private isWithinFilterRange(visitStartMinutes: number, visitEndMinutes: number, filterStartMinutes: number, filterEndMinutes: number): boolean {
    // Handle case where the filter range spans over midnight
    if (filterEndMinutes < filterStartMinutes) {
      return (
        (visitStartMinutes === 0 || visitStartMinutes >= filterStartMinutes) &&
        visitEndMinutes <= filterEndMinutes
      );
    }

    // Return false if the visit end time is before the start time, as it should'b be shown since it's not within the filter range
    if (visitEndMinutes < visitStartMinutes) {
      return false;
    }

    // Otherwise, check if the visit is within the filter range
    return (
      visitStartMinutes >= filterStartMinutes && visitEndMinutes <= filterEndMinutes
    );
  }

  private removeTempVisits(visits: CameraVisit[]): CameraVisit[] {
    return visits.filter((visit) => visit?.snoozedDeleteWhenDone === false);
  }
}
