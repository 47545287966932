import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CrNotesFormComponent } from '../../control-room-notes/cr-notes-form/cr-notes-form.component';
import { MatButtonModule } from '@angular/material/button';
import { CrNotesListComponent } from '../../control-room-notes/cr-notes-list/cr-notes-list.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-add-note',
    templateUrl: './add-note.component.html',
    styleUrls: ['../../control-room.component.css'],
    standalone: true,
    imports: [NgClass, NgIf, CrNotesListComponent, MatButtonModule, CrNotesFormComponent, TranslateModule]
})
export class AddNoteComponent implements OnInit {

  constructor() { }

  showNoteForm = false;
  @Input() alert;

  addedCameraVisitNotes: string[] = [];
  @Input() noteText: string;
  @Output() noteTextKeeped = new EventEmitter<string>();

  ngOnInit(): void {
    if (this.noteText?.length > 0) this.showForm();
  }

  handleNoteText(text: string) {
    this.noteTextKeeped.emit(text);
  }

  showForm(){
    this.showNoteForm = true;
  }

  hideForm(){
    this.showNoteForm = false
  }

}
