import { Pipe, PipeTransform } from "@angular/core";
import { DeviceLocation } from "./devices.component";

@Pipe({
  name: "extractLocations",
  standalone: true,
  pure: true,
})
export class ExtractLocationsPipe implements PipeTransform {
  transform(deviceLocations: DeviceLocation[]): { id: string, name: string }[] {
    return deviceLocations?.map(({ id, name }) => ({ id, name })) ?? [];
  }
}
